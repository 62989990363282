import {
  Box,
  Drawer,
  IconButton,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React from "react";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import SuccessImage from "../../images/success.png";
import { useTranslation } from "react-i18next";
import configData from "./../../config.js";

const UpdateAttendance = ({
  updateAttendance,
  setUpdateAttendance,
  setAttendances,
  attendance,
  eventID,
  entryTypeData,
  setIsAttendanceUpdated,
}) => {
  const [memberCardNumber, setMemberCardNumber] = useState();
  const [selectedEntryType, setSelectedEntryType] = useState("");
  const [, setEntryFeePaid] = useState();
  const [paymentMode, setPaymentMode] = useState("");
  const [paymentTypes] = useState(["Card", "Cash", "Online"]);
  const [, setAttendanceDetail] = useState([]);
  const [, setEventType] = useState("");
  const [entryType, setEntryType] = useState();
  const token = localStorage.getItem("token");
  const loginMemberId = localStorage.getItem("memberId");
  const [, setMemberFoundStatus] = useState();
  const [, setMemberFoundMessage] = useState("");
  //const [eventId, setEventId] = useState();
  //const eventId = "8dd1c12c-88ba-4ac2-ab16-7e18c151f928";
  const [memberFullName, setMemberFullName] = useState();
  const [, setPriceOfEvent] = useState(0);
  const [, setEntryTypeId] = useState("");
  const memberId = localStorage.getItem("memberId");
  const [securityCode, setSecurityCode] = useState("");
  const [voucherNumber, setVoucherNumber] = useState("");
  const [recordAttendanceSuccess, setRecordAttendenceSuccess] = useState(false);
  const [apiStatus, setApiStatus] = useState();
  const [memberListForOffline, setMemberListForOffline] = useState([]);
  const [, setAttendanceId] = useState("");
  const [canSave, setCanSave] = useState(false);
  const [voucherNumberError, setVoucherNumberError] = useState("");
  const [securityCodeError, setSecurityCodeError] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (attendance) {
      setMemberCardNumber(attendance.memberCardNumber);
      setMemberFullName(
        attendance.fullName
          ? attendance.fullName
          : `${attendance?.firstName} ${attendance?.lastName}`
      );
      const ans = entryTypeData.find(
        (e) => e?.entryTypeName === attendance.entryTypeName
      );
      if (ans) {
        setSelectedEntryType(ans.entryTypeID);
      }
      setEntryFeePaid(attendance.entryFeePaid);
      setEntryType(attendance.entryTypeName);
    }
  }, [attendance]);

  useEffect(() => {
    if (recordAttendanceSuccess) {
      setTimeout(() => {
        setRecordAttendenceSuccess(false);
        setUpdateAttendance(false);
      }, 1000);
    }
  }, [recordAttendanceSuccess]);

  useEffect(() => {
    if (memberCardNumber && memberCardNumber.length === 9) {
      axios
        .get(
          `${configData.VENUE_URL}Attendance/getattendancebymember?EventId=${eventID}&MemberCardNumber=${memberCardNumber}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setMemberFoundStatus(true);
          if (res.isSuccess === true) {
            setMemberFoundMessage(null);
          } else {
            setMemberFoundMessage(
              "Booking not found. Please select the values from the dropdown below and the checkbox Create Booking"
            );
          }
        })
        .catch(() => {
          setMemberFoundStatus(false);
          setMemberFoundMessage("Member Number not recognised");
        });
    }
  }, [memberCardNumber]);

  useEffect(() => {
    if (selectedEntryType) {
      setPriceOfEvent(selectedEntryType.entryFee);
      setEntryTypeId(selectedEntryType.entryTypeID);
    }
  }, [selectedEntryType]);

  useEffect(() => {
    const fetchAttendanceData = async (memberCardNumber) => {
      const res = await fetch(
        `${configData.VENUE_URL}Attendance/getattendancebymember?EventId=${eventID}&MemberCardNumber=${memberCardNumber}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.json();
      return data;
    };

    fetchAttendanceData(memberCardNumber).then((data) => {
      if (data.isError) {
        // navigate("/");
      }

      if (data.statusCode === 200) {
        setAttendanceDetail(data.payload);
        setEventType(data.payload.eventTypeName);
        setEntryTypeId(data.payload.entryTypeID);
        setEntryFeePaid(data.payload.entryFeePaid);
        let selectedPaymentMode =
          data.payload.paymentMethodName === "cerditCard"
            ? "CreditCard"
            : data.payload.paymentMethodName;
        setPaymentMode(selectedPaymentMode);
      }
    });
  }, [memberCardNumber]);

  const fetchBookingAndAttendanceData = async () => {
    if (eventID) {
      axios
        .get(`${configData.VENUE_URL}Attendance/${eventID}/getattendance`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res?.data?.payload?.attendanceHistory.length < 1) {
            setAttendances([]);
          } else {
            setAttendances(res?.data?.payload?.attendanceHistory);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleUpdateAttendance = () => {
    if (voucherNumber.length > 0 && voucherNumber.length < 4) {
      setVoucherNumberError("Invalid Voucher code");
      return;
    }
    if (securityCode.length > 0 && securityCode.length < 4) {
      setSecurityCodeError("Invalid Voucher code");
      return;
    }
    const selectedEntry = entryTypeData.filter((entryType) => {
      return entryType.entryTypeID === selectedEntryType;
    });
    let entryFee = selectedEntry[0].entryFee;
    const attendanceData = [
      {
        attendanceID: attendance.attendanceID,
        entryTypeID: selectedEntry[0].entryTypeID,
        entryFeePaid: entryFee,
        memberCardNumber: parseInt(memberCardNumber),
        updatedBy: loginMemberId,
        paymentMethodName: paymentMode,
        voucherNumber: voucherNumber,
        voucherSecurityCode: securityCode,
      },
    ];

    axios
      .put(
        `${configData.VENUE_URL}Attendance/updateattendance`,
        JSON.stringify(attendanceData),
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setRecordAttendenceSuccess(true);
        if (!res.data.isSuccess) {
          setApiStatus(res.data.errorMessage);
        } else {
          setApiStatus(res.data.payload);
          fetchBookingAndAttendanceData();
          setIsAttendanceUpdated(true);
          setVoucherNumberError("");
          setSecurityCodeError("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (attendance) {
      if (
        attendance?.entryTypeName !== entryType ||
        attendance?.paymentType !== paymentMode ||
        attendance.fullName !== memberFullName
      ) {
        setCanSave(true);
      } else {
        setCanSave(false);
      }
    }
  }, [entryType, paymentMode, memberFullName]);

  const handleEntryType = (entryTypeValue) => {
    const selectedEntry = entryTypeData.filter((entryType) => {
      return entryType.entryTypeID === entryTypeValue;
    });
    setSelectedEntryType(entryTypeValue);
    let entryFee = selectedEntry[0].entryFee - attendance.entryFeePaid;
    setPriceOfEvent(entryFee);
    setEntryType(selectedEntry[0].entryTypeName);
  };

  // offline data
  useEffect(() => {
    if (!navigator.onLine) {
      let request = window.indexedDB.open("MyDatabase", 1);
      //for Prebooked MemberList
      request.onsuccess = (event) => {
        let db = event.target.result;
        let transaction = db.transaction(
          `EventAttendance${eventID}`,
          "readonly"
        );
        let objectStore = transaction.objectStore(`EventAttendance${eventID}`);
        let request = objectStore.getAll();
        request.onsuccess = (event) => {
          setMemberListForOffline(event.target.result);
          memberListForOffline?.map((member) => {
            if (member.memberCardNumber === memberCardNumber) {
              setPriceOfEvent(member.entryFeePaid);
              const retrieveEntryTypeID = entryTypeData?.find(
                (entryTypeItem) =>
                  entryTypeItem.entryTypeName === member.entryTypeName
              );
              setSelectedEntryType(retrieveEntryTypeID);
              setAttendanceId(member.attendanceID);
              setPaymentMode(member.paymentMethodName);
              handleRecordAttendance(member.attendanceID);
            }
          });
        };
      };
      return;
    }
  }, []);

  useEffect(() => {
    if (!navigator.onLine) {
      if (memberCardNumber && memberCardNumber.length === 9) {
        memberListForOffline?.map((member) => {
          if (member.memberCardNumber === memberCardNumber) {
            setPriceOfEvent(member.entryFeePaid);
            const retrieveEntryTypeID = entryType?.find(
              (entryTypeItem) =>
                entryTypeItem.entryTypeName === member.entryTypeName
            );
            setSelectedEntryType(retrieveEntryTypeID);
            setAttendanceId(member.attendanceID);
            setPaymentMode(member.paymentMethodName);
            handleRecordAttendance(member.attendanceID);
          }
        });
        //return;
      }
    }
  }, [memberCardNumber]);

  const handleRecordAttendance = (attendanceID) => {
    if (navigator.onLine === "offline") {
      let request = window.indexedDB.open("MyDatabase", 1);
      request.onsuccess = (event) => {
        let db = event.target.result;
        let transaction = db.transaction(
          `RecordAttendance${eventID}`,
          "readwrite"
        );
        let objectStore = transaction.objectStore(`RecordAttendance${eventID}`);

        objectStore.put({
          attendanceID: attendanceID,
          recordedBy: memberId,
        });

        // setRecordAttendenceSuccessMessage("Atttendance Recorded");
      };
      return;
    }
  };
  return (
    <div>
      <Drawer
        PaperProps={{
          sx: {
            width: "30%",
            height: "100vh",
          },
        }}
        anchor="right"
        open={updateAttendance}
        onClose={() => setUpdateAttendance(false)}
      >
        {!recordAttendanceSuccess ? (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                m: "2rem",
              }}
            >
              <Typography>
                <IconButton onClick={() => setUpdateAttendance(false)}>
                  <KeyboardBackspaceOutlinedIcon />
                </IconButton>
                {t("Edit Entry")}
              </Typography>
              <div
                className="form-floating mb-2"
                style={{ width: "100%", flex: "3" }}
              >
                <input
                  type="text"
                  className="form-control"
                  style={{ fontSize: "22px" }}
                  onChange={(e) => setMemberCardNumber(e.target.value)}
                  value={memberCardNumber}
                  disabled={true}
                  placeholder="Member Card Number"
                />
                <label htmlFor="floatingInput">{t("Member Card Number")}</label>
              </div>
              <div className="form-floating mb-2" style={{ width: "100%" }}>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setMemberFullName(e.target.value)}
                  value={memberFullName}
                  disabled
                  placeholder="Full Name"
                />
                <label htmlFor="floatingInput">{t("Full Name")}</label>
              </div>
              <FormControl sx={{ width: "100%" }} size="small">
                <InputLabel id="demo-simple-select-label">
                  {t("Entry Type")}
                </InputLabel>
                {selectedEntryType && (
                  <Select
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 40 * 4.5 + 4,
                        },
                      },
                    }}
                    labelId="Entry Type"
                    label={t("Entry Type")}
                    // defaultValue={entryType}
                    defaultValue={selectedEntryType}
                    onChange={(e) => handleEntryType(e.target.value)}
                  >
                    {entryTypeData?.map((entryType) => (
                      <MenuItem value={entryType.entryTypeID}>
                        {entryType.entryTypeName}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
              <FormControl sx={{ width: "100%" }} size="small">
                <InputLabel>{t("Payment Mode")}</InputLabel>
                <Select
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 40 * 4.5 + 4,
                      },
                    },
                  }}
                  labelId="Payment Mode"
                  label={"Payment Mode"}
                  value={paymentMode}
                  onChange={(e) => setPaymentMode(e.target.value)}
                  // disabled={!priceOfEvent ? true : false}
                >
                  {paymentTypes?.map((paymentType) => (
                    <MenuItem value={paymentType}>{paymentType}</MenuItem>
                  ))}
                </Select>
                <div
                  className="form-floating mt-2 mb-2"
                  style={{ width: "100%" }}
                >
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setVoucherNumber(e.target.value)}
                    value={voucherNumber}
                    placeholder="Voucher Number"
                  />
                  <label htmlFor="floatingInput">{t("Voucher Number")}</label>
                  {voucherNumberError && (
                    <div className="mb-2">
                      <span className="text-danger">{voucherNumberError}</span>
                    </div>
                  )}
                </div>
                <div
                  className="form-floating mt-2 mb-2"
                  style={{ width: "100%" }}
                >
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setSecurityCode(e.target.value)}
                    value={securityCode}
                    placeholder="Security Code"
                  />
                  <label htmlFor="floatingInput">{t("Security Code")}</label>
                  {securityCodeError && (
                    <div className="mb-2">
                      <span className="text-danger">{securityCodeError}</span>
                    </div>
                  )}
                </div>
              </FormControl>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                //alignItems: "center",
                gap: "1rem",
              }}
            >
              <Button
                sx={{
                  textTransform: "none",
                  width: "40%",
                  backgroundColor: "lightgray",
                  //m: "auto",
                  color: "grey",
                  p: "0.5rem 0.5rem",
                  "&:hover": {
                    backgroundColor: "lightgray",
                  },
                }}
                onClick={() => setUpdateAttendance(false)}
              >
                {t("Cancel")}
              </Button>
              <Button
                sx={{
                  textTransform: "none",
                  width: "40%",
                  backgroundColor: "orange",
                  //m: "0 auto",
                  color: "#fff",
                  p: "0.5rem 0.5rem",
                  "&:hover": {
                    backgroundColor: "orange",
                  },
                }}
                onClick={handleUpdateAttendance}
                disabled={!canSave ? true : false}
                className={!canSave ? "not-allowed" : null}
              >
                {t("Save")}
              </Button>
            </Box>
          </>
        ) : (
          <Box>
            <Typography>
              <IconButton onClick={() => setUpdateAttendance(false)}>
                <KeyboardBackspaceOutlinedIcon />
              </IconButton>
              Success
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                mt: "7rem",
                gap: "2rem",
              }}
            >
              <img width="50%" src={SuccessImage} alt="" />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "none",
                  backgroundColor: "lightgrey",
                  padding: "0.7rem",
                }}
              >
                <Typography variant="h6" sx={{ color: "green" }}>
                  Successful!
                </Typography>
                <Typography variant="paragraph">{apiStatus}</Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Drawer>
    </div>
  );
};

export default UpdateAttendance;
