import { IconButton,Button, Typography } from "@mui/material";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { useEffect, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import PhoneInput from "react-phone-input-2";
import configData from "../../config.js";
import {
  phoneNoMapping,
  maskEmail,
  validateEmailReg,
} from "../CommonFunctions";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Box } from "@mui/system";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import SuccessImage from "../../images/success.png";

const MemberDetailModal = ({
  showModel,
  findMemberID,
  setShowModel,
  setFindMemberID,
  eventID,
  setAttendances,
  setIsAttendanceUpdated,
  isDetailPage,
  setMemberUpdated,
}) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [memberNumber, setMemberNumber] = useState("");
  const [lastName, setLastName] = useState("");
  const [postCode, setPostCode] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileCountryCode, setMobileCountryCode] = useState("44");
  const [dob, setDob] = useState(new Date());
  const [dobFlag, setDobFlag] = useState("");
  const [gender, setGender] = useState("");
  const [town, setTown] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [, setTownError] = useState("");
  const [dobError, setDobError] = useState("");
  const token = localStorage.getItem("token");
  const [memberId, setMemberId] = useState("");
  const [emailFlag, setEmailFlag] = useState("");
  const [phoneFlag, setPhoneFlag] = useState("");
  const [flatNumber, setFlatNumber] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [area, setArea] = useState("");
  const [county, setCounty] = useState("");
  const [addrCountryCode, setAddrCountryCode] = useState("");
  const [updateMemberSuccess, setUpdateMemberSuccess] = useState(false);
  const [postAreaList, setPostAreaList] = useState([]);
  const [localityList,setLocalityList] = useState([])
  const [postTownList,setPostTownList] = useState([])
  const [postStreetList,setPostStreetList] = useState([])
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [isUnknown, setIsUnknown] = useState();
  const [isValid, setIsValid] = useState(false);
  const [firstNameError,setFirstNameError] = useState('')
  const [lastNameError,setLastNameError] = useState('')
  const [completeAddress,setCompleteAddress] = useState("")
  const [completeAddList,setCompleteAddList] = useState([])
  const [hideAdd,setHideAddress] = useState(true)

  const fetchMemberData = async (findMemberID) => {
    const res = await fetch(
      `${configData.VENUE_URL}Member/${findMemberID}/getmember`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await res.json();
    return data;
  };

  useEffect(() => {
    if(firstName?.length === 0 || lastName?.length === 0 || !validateEmailReg(email) || !gender){
      setIsValid(false)
    }else{
      setIsValid(true)
    }
  }, [firstName,lastName,email,gender])

  useEffect(() => {
    if(firstName?.length===0){
      setFirstNameError('Invalid First Name')
    }else {
      setFirstNameError('')
    }
    if(lastName?.length===0){
      setLastNameError('Invalid Last Name')
    }else {
      setLastNameError('')
    }
    if(!validateEmailReg(email)){
      setEmailError('Invalid Email address')
    }else {
      setEmailError('')
    }
    if(mobileNumber && mobileNumber.length != 10){
      setMobileNumberError('Invalid Mobile Number')
    }else{
      setMobileNumberError('')
    }
    if(dob && !validateDate(dob)){
      setDobError('Invalid Date')
    }else{
      setDobError('')
    }
  }, [firstName,lastName,email,gender,mobileNumber,dob])

  useEffect(() => {
    if (findMemberID) {
      fetchMemberData(findMemberID).then((d) => {
        if (d.isError) {
        }

        if (d.statusCode === 200) {
          const data = d.payload;
          if (data) {
            let countryCode = data?.mobileCountyCode ?? null;
            let mobileNumber = data?.mobileNumber ?? null;
            if (data?.mobileNumber?.length > 10) {
              let num = data.mobileNumber.toString();
              countryCode = num.substring(0, 2);
              mobileNumber = num.substring(2);
            }
            let date = moment(data?.dateOfBirth).format("DD/MM/YYYY");
            setMemberId(data?.memberID);
            setFirstName(data?.firstName);
            setMemberNumber(data?.memberCardNumber);
            setLastName(data?.lastName);
            if (data?.firstName === "New" || data?.firstName === "Visitor") {
              setIsAnonymous(true);
            }
            setEmail(data?.email);
            setEmailFlag(data?.email ? maskEmail(data?.email) : "");
            setMobileNumber(mobileNumber);
            setPhoneFlag(mobileNumber ? phoneNoMapping(mobileNumber) : "");
            setMobileCountryCode(countryCode ?? "44");
            setDob(date);
            setDobFlag(date ? "**/**/****" : "");
            setGender(data?.gender);
            setStreetNumber(data?.postAddr1);
            setFlatNumber(data?.postAddr0)
            if (data?.addrPostcode) {
              fetchPostDetails(data?.addrPostcode);
            } else {
              setPostAreaList([data?.postAddr1]);
              setPostStreetList([data?.postAddr0])
              setLocalityList([data?.postAddr2])
              setPostTownList([data?.addrTown])
            }
            setArea(data?.postAddr2);
            setAddrCountryCode(data?.countryCode);
            setCounty(data?.addrCounty);
            setPostCode(data?.addrPostcode);
            setTown(data?.postAddrTown);
            setShowModel(true);
            setMobileNumberError("");
            setAddressError("");
            setTownError("");
            setDobError("");
          }
        }
      });
    }
  }, [findMemberID]);

  useEffect(() => {
    if (isUnknown && memberNumber.length > 1) {
      setEmailFlag("");
      setEmail(`${memberNumber}@ceroc-member-without-email.co.uk`);
    }
    if (isUnknown === false) {
      setEmailFlag("");
      setEmail("");
    }
  }, [isUnknown]);

  const handlePostCodeChange = (e) => {
    setPostCode(e.target.value)
    setFlatNumber("")
    setStreetNumber("")
    setTown("")
    setArea("")
  }

  const fetchBookingAndAttendanceData = async () => {
    if (eventID) {
      axios
        .get(`${configData.VENUE_URL}Attendance/${eventID}/getattendance`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res?.data?.payload?.attendanceHistory.length < 1) {
            setAttendances([]);
          } else {
            setAttendances(res?.data?.payload?.attendanceHistory);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleStoringData = () => {
    const data = {
      FirstName: firstName,
      LastName: lastName,
      DateOfBirth: dob,
      Gender: gender,
      IssuerTerritory: 0,
      EmailAddress: email,
      MobileNumber: mobileNumber,
      MobileCountryCode: mobileCountryCode,
      PostAddr0: flatNumber,
      Postcode: postCode,
      PostAddr1: streetNumber,
      PostAddr2: area,
      AddrCounty:county,
      PostAddrTown: town,
      PostcodeCountry:addrCountryCode,
      emailError:emailError,
      mobileNumberError:mobileNumberError
    }
    if(!localStorage.getItem(`updateMember-${memberNumber}`)){
      localStorage.setItem(`updateMember-${memberNumber}`, JSON.stringify(data));
  }
    setShowModel(false)
  }

  useEffect(() => {
    if(showModel && memberNumber && firstName==="New"){
      handleFetchingData()
    }else if(firstName==="New"){
      handleStoringData()
    }
  }, [showModel,memberNumber])

  const handleModal = (data) => {
    if (!showModel) {
      let date = moment(data?.dateOfBirth).format("DD/MM/YYYY");
      setMemberId(data?.memberID);
      setDob(date);
      setDobFlag(date ? "**/**/****" : "");
    } else {
      if(isAnonymous){
        handleStoringData()
      }
      setShowModel(false);
      setFindMemberID();
      setMemberId();
      setFirstName("");
      setMemberNumber("");
      setLastName("");
      setIsUnknown(false);
      setEmail("");
      setEmailFlag("");
      setMobileNumber("");
      setCompleteAddress("")
      setPhoneFlag("");
      setMobileCountryCode("44");
      setDob();
      setDobFlag("");
      setGender();
      setFlatNumber();
      setStreetNumber();
      setArea();
      setAddrCountryCode();
      setCounty();
      setPostCode();
      setTown();
      setMobileNumberError("");
      setAddressError("");
      setTownError("");
      setDobError("");
    }
  };

  function validateDate(dateString) {
    const re = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
    if(!re.test(dateString)){
      return false
    }
    const [day, month, year] = dateString.split('/').map(Number);
    const inputDate = new Date(year, month - 1, day);
    if (inputDate.getFullYear() !== year || inputDate.getMonth() + 1 !== month || inputDate.getDate() !== day) {
      return false
    }
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (inputDate > today) {
      return false
    }
    return true
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setEmailError("Please enter email");
      return;
    }
    if (!validateEmailReg(email)) {
      setEmailError("Invalid Email!");
      return;
    }
    if (dob && !validateDate(dob)) {
      setDobError("Invalid Date!");
    }

    setEmailError("");
    setMobileNumberError("");
    setAddressError("");
    setTownError("");
    setDobError("");

    const putData = {
      firstName: firstName,
      lastName: lastName,
      memberId: findMemberID,
      gender: parseInt(gender),
      mobileNumber: mobileNumber.length > 0 ? mobileNumber : null,
      mobileCountryCode: 44,
      postAddr0: flatNumber ?? "",
      postalcode: postCode,
      postAddr1: streetNumber,
      postAddr2: area ?? "",
      postAddrTown: town,
      postcodeCountry: addrCountryCode,
      updatedBy: memberId,
      dateOfBirth: "1997-02-26",
      email: email,
    };
    if (isAnonymous) {
      putData.isAnonymous = true;
      putData.email = email;
    }
    const endpoint = isAnonymous ? "UpdateAnonymousMember" : "UpdateMembershipDetails"
    await fetch(
      `${configData.VENUE_URL}Member/${endpoint}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(putData),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        //handle data
        if (data.statusCode === 200) {
          if (isDetailPage) {
            setMemberUpdated(true);
          }
          setHideAddress(true)
          setCompleteAddress('')
          setUpdateMemberSuccess(true);
          fetchBookingAndAttendanceData();
          setIsAttendanceUpdated(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleBlurredInput = (e, type) => {
    switch (type) {
      case "email":
        if (emailFlag) {
          setEmail("");
          setEmailFlag("");
        } else {
          setEmail(e.target.value);
        }
        break;
      case "phone":
        if (phoneFlag) {
          setPhoneFlag("");
          setMobileNumber("");
        } else {
          setMobileNumber(e.target.value);
        }
        break;
      case "dob":
        if (dobFlag) {
          setDobFlag("");
          setDob("");
        } else {
          setDob(e.target.value);
        }
        break;
        break;
    }
  };

  const fetchPostDetails = (code) => {
    fetch(`${configData.MEMBER_URL}Members/postcodelookup/${code}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 404) {
          setPostStreetList([])
          setPostAreaList([]);
          setLocalityList([])
          setPostTownList([])
        }

        if (data.result) {
          const postList = data.result ? data.result.map((e) => e?.addr1) : [];
          const streetList = data.result ? data.result.map(e=>e?.addr0):[]
          const areaList = data.result ? data.result.map(e=>e?.addr2):[]
          const townList = data.result ? data.result.map(e=>e?.addrTown):[]
          const completeAdd = data.result ? data.result.map((e)=>{
            return `${e.addr0} ${e.addr1} ${e.addr2} ${e.addrTown}`
          }):[]
          setPostStreetList(streetList)
          setPostAreaList(postList);
          setLocalityList(areaList)
          setPostTownList(townList)
          setCompleteAddList(completeAdd)
          setHideAddress(false)
          const postCodeData = data.result[0];
          setAddrCountryCode(postCodeData.addrCountryCode);
        }
      });
  };

  const handleFetchingData = () => {
    const data = JSON.parse(localStorage.getItem(`updateMember-${memberNumber}`))
    if(data){
    setFirstName(data.FirstName)
    setLastName(data.LastName)
    setDob(data.DateOfBirth);
    setMobileCountryCode(data.MobileCountryCode??'44');
    setPostCode(data.postCode);
    setTown(data.PostAddrTown);
    setMobileNumber(data.MobileNumber)
    setEmail(data.EmailAddress)
    setGender(data.Gender)
    setEmailError(data.emailError);
    setMobileNumberError(data.mobileNumberError);
    setFlatNumber(data.PostAddr0)
    setStreetNumber(data.PostAddr1)
    setArea(data.PostAddr2)
    setCounty(data.AddrCounty)
    setAddrCountryCode(data.PostcodeCountry)
    }
    localStorage.removeItem(`updateMember-${memberNumber}`)
  }

  const selectCompleteAddress = (val) => {
    setCompleteAddress(val)
    setFlatNumber(postStreetList[val])
    setStreetNumber(postAreaList[val])
    setArea(localityList[val])
    setTown(postTownList[val])
    setHideAddress(true)
  }

  const handleResetFields = () => {
    setFirstName("New")
    setLastName("")
    setDob("");
    setMobileCountryCode("44");
    setPostCode("");
    setTown("");
    setMobileNumber("")
    setEmail("")
    setGender("")
    setEmailError("");
    setMobileNumberError("");
    setFlatNumber("")
    setCompleteAddress('')
    setHideAddress(true)
    setStreetNumber("")
    setArea("")
    setCounty("")
    setAddrCountryCode("")
    localStorage.removeItem(`updateMember-${memberNumber}`)
  };

  const handleDate = (e) => {
    let input = e.target;
    if (e.charCode < 47 || e.charCode > 57) {
      e.preventDefault();
    }
    let len = input.value.length;

    if (len !== 1 || len !== 3) {
      if (e.charCode === 47) {
        e.preventDefault();
      }
    }

    if (len === 2) {
      input.value += "/";
    }

    if (len === 5) {
      input.value += "/";
    }
  };

  useEffect(() => {
    if (updateMemberSuccess) {
      setTimeout(() => {
        setUpdateMemberSuccess(false);
        setShowModel(false);
        handleResetFields()
        setFindMemberID();
      }, 1000);
    }
  }, [updateMemberSuccess]);

  return (
    <div>
      <Modal className="edit-modal" show={showModel} onHide={handleModal}>
        <Modal.Body>
          <div className="member-form d-flex align-items-center">
            <div className="container">
              <div className="row">
                {!updateMemberSuccess ? (
                  <div className="col-lg-10 col-xl-11 mx-auto">
                      <h4 className="mb-3">
                        <BsArrowLeft
                          onClick={handleModal}
                          style={{ marginRight: "10px" }}
                        />
                        {"Update Member"}
                      </h4>
                      <div className="dis-flx">
                      <div className="form-floating mb-2 flx-num">
                        <input
                          type="text"
                          className="border form-control shadow-none bg-light"
                          value={memberNumber}
                          readOnly
                          placeholder="Member Card Number"
                        />
                        <label htmlFor="floatingInput">
                          {"Member Card Number"}
                        </label>
                      </div>
                      {isAnonymous && 
                    <div className="mar-num">
                    <Button
                    className="dis-btn"
              variant="contained"
              onClick={(e)=>handleResetFields(e)}
            >
              Discard
            </Button>
                    </div>
}
                      </div>
                      <div className="form-floating mb-2">
                        <input
                          type="text"
                          className="border form-control shadow-none"
                          onChange={(e) => setFirstName(e.target.value)}
                          value={firstName}
                          placeholder="First Name"
                        />
                        <label htmlFor="floatingInput">{"First Name"}</label>
                        {firstNameError && (
                        <div className="mb-2">
                          <span className="text-danger mb-2">{firstNameError}</span>
                        </div>
                      )}
                      </div>
                      <div className="form-floating mb-2">
                        <input
                          type="text"
                          className="border form-control shadow-none"
                          onChange={(e) => setLastName(e.target.value)}
                          value={lastName}
                          placeholder="Last Name"
                        />
                        <label htmlFor="floatingInput">{"Last Name"}</label>
                        {lastNameError && (
                        <div className="mb-2">
                          <span className="text-danger mb-2">{lastNameError}</span>
                        </div>
                      )}
                      </div>
                      <div style={{ display: "flex" }}>
                        <div
                          className="form-floating mb-2"
                          style={{ flex: "3" }}
                        >
                          <input
                            className="form-control border shadow-none"
                            onChange={(e) => handleBlurredInput(e, "email")}
                            value={emailFlag ? emailFlag : email}
                            placeholder="Email"
                          />
                          <label htmlFor="floatingInput">{"Email"}</label>
                          {emailError && (
                            <div className="mb-2">
                              <span className="text-danger mb-2">
                                {emailError}
                              </span>
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "end",
                            marginLeft: "4px",
                          }}
                        >
                          <span style={{ fontSize: "14px" }}>Unknown</span>
                          <input
                            value={isUnknown}
                            disabled={memberNumber?.length < 3}
                            onClick={(e) => {
                              setIsUnknown(e.target.checked);
                            }}
                            style={{ height: "20px", width: "20px" }}
                            type="checkbox"
                          />
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="form-floating col-md-4 mb-2 pr-1">
                          <PhoneInput
                            style={{ width: "2rem" }}
                            country={"uk"}
                            className="mt-2"
                            specialLabel={"Country Code"}
                            value={mobileCountryCode}
                            onChange={(abc) => setMobileCountryCode(abc)}
                          />
                        </div>
                        <div className="form-floating col-md-8 mb-2">
                          <input
                            type="text"
                            onChange={(e) => handleBlurredInput(e, "phone")}
                            value={phoneFlag ? phoneFlag : mobileNumber}
                            maxLength={10}
                            placeholder="Mobile Number"
                            className="form-control mb-2 mt-2"
                          />
                          <label htmlFor="floatingInput">
                            {"Mobile Number"}
                          </label>
                          {mobileNumberError && (
                            <div className="mb-2">
                              <span className="text-danger mb-2">
                                {mobileNumberError}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-floating mb-2">
                        <input
                          type="text"
                          className="form-control"
                          disabled={!isAnonymous}
                          maxLength="10"
                          placeholder="dd/mm/yyyy"
                          onKeyPress={handleDate}
                          sx={{ width: "100%" }}
                          value={dobFlag ? dobFlag : dob}
                          onChange={(e) => handleBlurredInput(e, "dob")}
                        />
                        <label htmlFor="floatingInput">
                          {t("Date of Birth (dd/mm/yyyy)")}
                        </label>
                        {dobError && (
                          <div className="mb-2">
                            <span className="text-danger">{dobError}</span>
                          </div>
                        )}
                      </div>

                      <div className="form-floating mb-2">
                        <select
                          className="form-select mt-2"
                          aria-label="Floating label select example"
                          value={gender}
                          onChange={(e) => setGender(e.target.value)}
                        >
                          <option value="0">Unknown</option>
                          <option value="1">Male</option>
                          <option value="2">Female</option>
                          <option value="3">Other</option>
                        </select>
                        <label htmlFor="floatingSelect">{"Gender"}</label>
                      </div>
                      <div className="form-floating mb-2">
                        <select
                          className="form-select mt-2"
                          aria-label="Floating label select example"
                          value={addrCountryCode ?? "UK"}
                          onChange={(e) => setAddrCountryCode(e.target.value)}
                        >
                          <option value="UK">UK- United Kingdom</option>
                          <option value="IE">IE- Ireland</option>
                          <option value="FR">FR - France</option>
                          <option value="NL">NL - Netherlands </option>
                        </select>
                        <label htmlFor="floatingSelect">
                          {"Address Country Code"}
                        </label>
                      </div>
                      <div className="form-floating mb-2">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => handlePostCodeChange(e)}
                          onKeyDown={(e)=>{
                            if(e.key == "Enter"){
                              if (postCode?.length > 0 && navigator.onLine) {
                                fetchPostDetails(postCode);
                              }
                            }
                          }}
                          value={postCode}
                          placeholder="Postcode"
                        />
                        <label htmlFor="floatingInput">{"Postcode"}</label>
                        {addressError && (
                          <div className="mb-2">
                            <span className="text-danger">{addressError}</span>
                          </div>
                        )}
                      </div>
                      {!hideAdd && <div className="form mb-2">
                      <label className="postcode-label">{"Please select your address"}</label>
                        <select
                          className="postcode-selector"
                          aria-label="Floating label select example"
                          onChange={(e) => selectCompleteAddress(e.target.value)}
                          value={completeAddress}
                        >
                        {completeAddList && completeAddList.map((e,i)=>(
                          <option key={i} value={i}>{e}</option>
                        ))
                        }
                        </select>
                      </div>}
                      <div className="form-floating mb-2">
                      <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setFlatNumber(e.target.value)}
                          value={flatNumber}
                          placeholder="Flat/Building"
                        />
                        <label htmlFor="floatingInput">{t("Flat/Building")}</label>
                      </div>
                      <div className="form-floating mb-2">
                      <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setStreetNumber(e.target.value)}
                          value={streetNumber}
                          placeholder={"Number & Street"}
                        />
                      <label htmlFor="floatingSelect">{"Number & Street"}</label>
                      </div>
                      <div className="form-floating mb-2">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setArea(e.target.value)}
                          value={area}
                          placeholder="Area"
                        />
                        <label htmlFor="floatingInput">{t("Area")}</label>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setTown(e.target.value)}
                          value={town}
                          placeholder="Address"
                        />
                        <label htmlFor="floatingInput">{t("Town")}</label>
                      </div>
                      <button
                        style={{
                          position: "sticky",
                          right: 0,
                          bottom: "0.5rem",
                          zIndex: "100",
                        }}
                        disabled={!isValid}
                        onClick={(e)=>handleSubmit(e)}
                        className={`btn ${
                          isValid ? "background-green" : "background-red"
                        } form-control mt-3 text-white`}
                      >
                        {"Update Now"}
                      </button>
                  </div>
                ) : (
                  <Box>
                    <Typography>
                      <IconButton>
                        <KeyboardBackspaceOutlinedIcon />
                      </IconButton>
                      Success
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        mt: "7rem",
                        gap: "2rem",
                      }}
                    >
                      <img width="50%" src={SuccessImage} alt="" />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "none",
                          backgroundColor: "lightgrey",
                          padding: "0.7rem",
                        }}
                      >
                        <Typography variant="h6" sx={{ color: "green" }}>
                          Successful!
                        </Typography>
                        <Typography variant="paragraph">
                          {"Member updated successfully!"}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MemberDetailModal;
