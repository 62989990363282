import {
  Box,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import QrCodeScanner from "./QrCodeScanner";
import moment from "moment";

const FindMemberAttendanceOffline = ({
  recordAttendence,
  setIsLoading,
  selectedUserId,
  activeUsers,
  newAddedUsers,
  eventID,
  t,
  mode,
  setUpdateList,
  attendanceList,
  setDefaultSort
}) => {
  const [memberCardNumber, setMemberCardNumber] = useState("");
  const [entryType, setEntryType] = useState([]);
  const [selectedEntryType, setSelectedEntryType] = useState("");
  const [paymentMode, setPaymentMode] = useState(["Card"]);
  const [memberFoundStatus] = useState();
  const [memberFoundMessage] = useState("");
  const [priceOfEvent, setPriceOfEvent] = useState(0);
  const [entryTypeId, setEntryTypeId] = useState("");
  const memberId = localStorage.getItem("memberId");
  const [memberCardNumberError, setMemberCardNumberError] = useState();
  const [entryTypeError, setEntryTypeError] = useState();
  const [, setPaymentModeError] = useState();
  const [recordAttendanceSuccess] = useState(false);
  const [recordAttendenceSuccessMessage, setRecordAttendenceSuccessMessage] =
    useState("");
  const [memberFullName, setMemberFullName] = useState("");
  const [memberNameError, setMemberNameError] = useState();
  const [attendanceId] = useState("");
  const [attendanceError] = useState("");
  const [paymentPaid] = useState(false);
  const [scanQRPressed, setScanQRPressed] = useState(false);
  const [qrCodeMemberId, setQrCodeMemberId] = useState("");
  const textFieldForMemberIdRef = React.useRef(null);
  const textFieldForFullnameRef = React.useRef(null);
  const newBtnRef = React.useRef(null);
  const visitorBtnRef = React.useRef(null);
  const scanQrBtnRef = React.useRef(null);
  const submitBtnRef = React.useRef(null);
  const entryTypeRef = React.useRef(null);
  const paymentTypeRef = React.useRef(null);
  const modalBtnRef = React.useRef(null);
  const [userType, setUserType] = useState("");
  const [emailAlert, setEmailAlert] = useState(false);
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [gender, setGender] = useState("");
  const [elementRefreshCounter,setElementRefreshCounter] = useState(0)

  //for QR Code
  const [userMemberId, setUserMemberId] = useState();

  const handleCloseAlert = () => {
    setEmailAlert(false);
    if(memberCardNumberError){
      newBtnRef.current.focus()
    }else{
      entryTypeRef.current.focus();
    }
  };

  useEffect(() => {
    if (mode === "offline") {
      let request = window.indexedDB.open("MyDatabase", 1);
      // For Entry Type
      request.onsuccess = (event) => {
        let db = event.target.result;
        let transaction = db.transaction(`EntryType${eventID}`, "readonly");
        let objectStore = transaction.objectStore(`EntryType${eventID}`);
        let request = objectStore.getAll();
        request.onsuccess = (event) => {
          let entryTypes = event.target.result[0];
          setEntryType(entryTypes);
          defaultEntryType();
        };
      };
      textFieldForMemberIdRef.current.focus();
    }
  }, [eventID]);

  useEffect(() => {
    if (qrCodeMemberId) {
      let flag = 0;
      if (activeUsers.length > 0) {
        activeUsers.map((e) => {
          if (e?.memberID === qrCodeMemberId) {
            setMemberCardNumber(e.memberCardNumber);
            setMemberFullName(`${e?.firstName} ${e?.lastName}`);
            setEmailAlert(e?.emailId?.length > 1 ? false : true);
            setEmail(e?.emailId);
            setGender(e?.gender);
            setMobileNumber(e?.mobileNumber);
            setScanQRPressed(false);
            flag = 1;
            return;
          }
        });
      }
      if (newAddedUsers.length > 0) {
        newAddedUsers.map((e) => {
          if (e?.memberID === qrCodeMemberId) {
            setMemberCardNumber(e.MemberPhysicalCardNumber);
            setMemberFullName(`${e?.firstName} ${e?.lastName}`);
            setEmailAlert(e?.emailId?.length > 1 ? false : true);
            setUserMemberId(e?.memberID);
            setEmail(e?.emailId);
            setGender(e?.gender);
            setMobileNumber(e?.mobileNumber);
            setScanQRPressed(false);
            flag = 1;
            return;
          }
        });
      }
      if (flag) {
        setRecordAttendenceSuccessMessage("");
      } else {
        setEmailAlert(false);
        setEmail("");
        setGender("");
        setMobileNumber("");
        setRecordAttendenceSuccessMessage("Invalid QR Code");
      }
    }
  }, [qrCodeMemberId]);

  const getMemberInfo = () => {
    if (memberCardNumber && memberCardNumber.length > 3) {
      setIsLoading(true)
      let flag = 0;
      attendanceList.find((e) => {
        if (e.memberCardNumber === memberCardNumber) {
          setRecordAttendenceSuccessMessage("MEMBER ALREADY ENTERED");
          setElementRefreshCounter(elementRefreshCounter+1)
        } else {
          setRecordAttendenceSuccessMessage("");
        }
      });
      if (activeUsers.length > 0) {
        activeUsers.map((e) => {
          if (e?.memberCardNumber === memberCardNumber) {
            setMemberFullName(`${e?.firstName} ${e?.lastName}`);
            setEmailAlert(e?.emailId?.length > 1 ? false : true);
            setEmail(e?.emailId);
            setGender(e?.gender);
            setMobileNumber(e?.mobileNumber);
            setUserMemberId(e?.memberID);
            flag = 1;
            return;
          }
        });
      }
      if (newAddedUsers.length > 0) {
        newAddedUsers.map((e) => {
          if (e?.MemberPhysicalCardNumber === memberCardNumber) {
            setMemberFullName(`${e?.FirstName} ${e?.LastName}`);
            setEmailAlert(e?.emailId?.length > 1 ? false : true);
            setEmail(e?.emailId);
            setGender(e?.gender);
            setMobileNumber(e?.mobileNumber);
            flag = 1;
            return;
          }
        });
      }
      if (flag) {
        setMemberCardNumberError(``);
      } else {
        setMemberFullName("");
        setUserType("");
        setEmail("");
        setGender("");
        setMobileNumber("");
        setEmailAlert(false);
        setMemberCardNumberError(`${t("Member Number not recognised")}`);
        setTimeout(() => {
          newBtnRef.current.focus()
        }, 300);
      }
      setIsLoading(false)
    }
  }

  const defaultEntryType = (val = "Standard Entry") => {
    const ans = entryType.find((e) => e?.entryTypeName.includes(val));
    if (ans) {
      setSelectedEntryType([ans.entryTypeID]);
    }
  };

  useEffect(() => {
    if (entryType.length > 0) {
      defaultEntryType();
    }
  }, [entryType]);

  useEffect(() => {
    if (selectedUserId) {
      setMemberCardNumber(selectedUserId?.memberCardNumber);
      textFieldForMemberIdRef.current.focus();
      setMemberFullName(
        `${selectedUserId?.firstName} ${selectedUserId?.lastName}`
      );
      defaultEntryType(selectedUserId?.entryTypeName);
    }
  }, [selectedUserId]);

  useEffect(() => {
    if (selectedEntryType && !paymentPaid && !attendanceId) {
      const retrieveEntryType = entryType?.find(
        (entryTypeItem) => entryTypeItem.entryTypeID === selectedEntryType[0]
      );
      setPriceOfEvent(retrieveEntryType?.entryFee ?? 0);
      setEntryTypeId(retrieveEntryType?.entryTypeID);
    } else {
      if (selectedEntryType) {
        const retrieveEntryType = entryType?.find(
          (entryTypeItem) => entryTypeItem.entryTypeID === selectedEntryType
        );
        if (retrieveEntryType) {
          setPriceOfEvent(retrieveEntryType?.entryFee ?? 0);
          setEntryTypeId(retrieveEntryType?.entryTypeID);
        }
      }
    }
  }, [selectedEntryType]);

  const handleCreateAttendance = () => {
    let flag = 0;
    setMemberCardNumberError("");
    setUserType("");
    setEntryTypeError("");
    setPaymentModeError("");
    if (memberCardNumber === "") {
      setMemberCardNumberError(`${t("Member Card Number is Required")}`);
    } else if (memberCardNumber.length <= 3) {
      setMemberCardNumberError("Please Enter atleast 3 digit MemberCardNumber");
    }

    if (selectedEntryType === "") {
      setEntryTypeError(`${t("Please Select Entry Type")}`);
    }

    if (paymentMode === "") {
      setPaymentModeError(`${t("Please Select Payment Mode")}`);
    }

    if (memberFullName === "") {
      if (userType && userType.length > 1) {
        setMemberFullName(userType);
      } else {
        setMemberNameError(`${t("Please Enter Valid Name")}`);
      }
    }

    if (
      memberCardNumber === "" ||
      memberCardNumber.length <= 3 ||
      selectedEntryType === "" ||
      paymentMode === "" ||
      (memberFullName === "" && userType.length < 1)
    ) {
      return;
    }
    if (userType.length < 1) {
      if (activeUsers.length > 0) {
        activeUsers.find((e) => {
          if (e.memberCardNumber === memberCardNumber) {
            flag = 1;
          }
        });
      }
      if (newAddedUsers.length > 0) {
        newAddedUsers.find((e) => {
          if (e.MemberPhysicalCardNumber === memberCardNumber) {
            flag = 1;
          }
        });
      }
    } else {
      flag = 1;
    }
    attendanceList.find((e) => {
      if (e.memberCardNumber === memberCardNumber) {
        flag = 2;
        setRecordAttendenceSuccessMessage("MEMBER ALREADY ENTERED");
        setUpdateList(true);
        setMemberFullName("");
        setMemberCardNumber("");
        setUserType("");
        defaultEntryType();
        setPaymentMode(["Card"]);
        setEmailAlert(false);
        setPriceOfEvent(0);
        setTimeout(() => {
          setRecordAttendenceSuccessMessage("");
        }, 3000);
      }
    });
    const entryTypeData = entryType.find(
      (e) => e?.entryTypeID === selectedEntryType[0]
    );
    if (flag === 1) {
      let request = window.indexedDB.open("MyDatabase", 1);
      request.onsuccess = (event) => {
        let db = event.target.result;
        let transaction = db.transaction(
          `CreateAttendance${eventID}`,
          "readwrite"
        );
        let splittedName = memberFullName.split(" ");
        let objectStore = transaction.objectStore(`CreateAttendance${eventID}`);
        objectStore.put({
          memberID: userMemberId,
          fullName: memberFullName,
          firstName: splittedName[0] ?? "",
          lastName: splittedName[1] ?? "",
          EventID: eventID,
          memberCardNumber: memberCardNumber * 1,
          EventTypeName: entryTypeData?.entryTypeName,
          entryTypeName: entryTypeData?.entryTypeName,
          entryTypeID: entryTypeId,
          entryFeePaid: priceOfEvent,
          paymentMethodName: priceOfEvent === 0 ? "No Payment" : paymentMode[0],
          email: email,
          mobileNumber: mobileNumber,
          gender: gender,
          entryTime: moment().format("DD/MM/YYYY, hh:mm:ss"),
          voucherNumber: null,
          notes: null,
          voucherSecurityCode: null,
          createdBy: memberId,
          isAnonymous: userType && userType.length > 0 ? true : false,
          name: userType,
          entryDateTime: new Date(),
        });

        let transaction1 = db.transaction(
          `EventAttendance${eventID}`,
          "readwrite"
        );
        let objectStore1 = transaction1.objectStore(
          `EventAttendance${eventID}`
        );
        objectStore1.put({
          id: attendanceList?.length + 1 ?? 4,
          memberID: userMemberId,
          fullName: memberFullName,
          firstName: splittedName[0] ?? "",
          lastName: splittedName[1] ?? "",
          EventID: eventID,
          memberCardNumber: memberCardNumber * 1,
          EventTypeName: entryTypeData?.entryTypeName,
          entryTypeName: entryTypeData?.entryTypeName,
          entryTime: moment().format("DD/MM/YYYY, hh:mm:ss"),
          entryTypeID: entryTypeId,
          entryFeePaid: priceOfEvent,
          paymentMethodName: priceOfEvent === 0 ? "No Payment" : paymentMode[0],
          email: email,
          mobileNumber: mobileNumber,
          gender: gender,
          voucherNumber: null,
          notes: null,
          voucherSecurityCode: null,
          createdBy: memberId,
          isAnonymous: userType && userType.length > 0 ? true : false,
          name: userType,
          entryDateTime: new Date(),
        });
        setRecordAttendenceSuccessMessage("MEMBER ADMITTED");
        setElementRefreshCounter(elementRefreshCounter+1)
        setUpdateList(true);
        setMemberFullName("");
        setMemberCardNumber("");
        setDefaultSort(true)
        setUserType("");
        defaultEntryType();
        setPaymentMode(["Card"]);
        setPriceOfEvent(0);
        setTimeout(() => {
          setRecordAttendenceSuccessMessage("");
        }, 3000);
      };
    } else {
      if (flag === 0) {
        setMemberCardNumberError(`${t("Member Number not recognised")}`);
        setTimeout(() => {
          newBtnRef.current.focus()
        }, 300);
      }
    }
    textFieldForMemberIdRef.current.focus();
  };

  const handleChangeEventTypeMode = (event) => {
    const { options } = event.target;
    let value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setSelectedEntryType(value);
  };

  const handleChangePaymentMode = (event) => {
    const { options } = event.target;
    let value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setPaymentMode(value);
  };

  const renderEmailAlert = () => {
    if (emailAlert) {
      setTimeout(() => {
        modalBtnRef.current.focus();
      }, 500);
      return (
        <div
          className="modal modalOffline show"
          style={{
            display: "block",
            margin: "3rem 0 0 0rem",
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          }}
        >
          <Modal.Dialog
            className="offlineData"
            sx={{ margin: "9rem 0 0 0rem" }}
          >
            <Modal.Header>
              <Modal.Title className="email-alert">
                NO EMAIL ADDRESS.
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <h5 className="email-alert">
                Please ask the member for an email address and update their
                record.
              </h5>
            </Modal.Body>

            <Modal.Footer>
              <Button
                ref={modalBtnRef}
                variant="secondary"
                onClick={() => handleCloseAlert()}
              >
                Okay
              </Button>
            </Modal.Footer>
          </Modal.Dialog>
        </div>
      );
    }
  };

  return (
    <div>
      {!recordAttendanceSuccess ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              m: "2rem",
              mt: "3rem",
            }}
          >
            {recordAttendenceSuccessMessage ? (
              <h6
                className={
                  recordAttendenceSuccessMessage
                    ? recordAttendenceSuccessMessage.includes("ALREADY")
                      ? "text-danger"
                      : "text-success"
                    : "text-danger"
                }
              >
                {recordAttendenceSuccessMessage
                  ? recordAttendenceSuccessMessage + " !"
                  : ""}
              </h6>
            ) : (
              ""
            )}
            {attendanceError ? (
              <h6 className="text-danger mb-2">{attendanceError}</h6>
            ) : (
              ""
            )}
            <div style={{ display: "flex" }}>
              <div
                className="form-floating mb-2"
                style={{ width: "100%", flex: "3" }}
              >
                <input
                  type="text"
                  className="form-control"
                  style={{ fontSize: "37px" }}
                  onChange={(e) => setMemberCardNumber(e.target.value)}
                  value={memberCardNumber}
                  ref={textFieldForMemberIdRef}
                  onKeyDown={(event) => {
                    const { key } = event;
                    if (key === "Enter") {
                      if (memberCardNumber) {
                        getMemberInfo()
                        setTimeout(() => {
                          if(memberCardNumberError?.length === 0){
                            entryTypeRef.current.focus();
                          }
                        }, 800);
                      } else if (!memberCardNumber) {
                        scanQrBtnRef.current.focus();
                      } else if (memberCardNumber && !memberFullName) {
                        textFieldForMemberIdRef.current.blur();
                      }
                    }
                  }}
                  placeholder="Member Card Number"
                />
                <label htmlFor="floatingInput">{t("Member Card Number")}</label>
              </div>
              <Button
                tabIndex={`${memberCardNumber ? "-1" : ""}`}
                ref={scanQrBtnRef}
                onKeyDown={(event) => {
                  const { key } = event;
                  if (key === "Enter") {
                    textFieldForFullnameRef.current.focus();
                  }
                }}
                sx={{
                  textTransform: "none",
                  width: "90%",
                  backgroundColor: "#FFF1E6",
                  color: "#F87B03",
                  flex: "1",
                  p: "0.5rem 0.5rem",
                  height: "3.7rem",
                  ml: "10px",
                  "&:hover": {
                    backgroundColor: "#FFF1E6",
                  },
                }}
                onClick={() => {
                  setScanQRPressed(true);
                }}
              >
                Scan QR
              </Button>
            </div>
            {memberCardNumberError ? (
              <>
                {!userType && (
                  <Typography
                    variant="paragraph"
                    sx={{ fontSize: "14px", color: "red", mt: "-20px" }}
                  >
                    {memberCardNumberError}
                  </Typography>
                )}
                <div style={{ display: "flex" }}>
                  <Button
                    sx={{
                      textTransform: "none",
                      borderRadius: "1rem",
                      fontWeight: "915",
                    }}
                    onClick={() => setUserType("New")}
                    style={{
                      border: "3px solid #fd7e14",
                      backgroundColor: `${
                        userType === "New" ? "#fd7e14" : "#fff"
                      }`,
                      color: `${userType === "New" ? "#fff" : "black"}`,
                    }}
                    variant="contained"
                    ref={newBtnRef}
                    onKeyDown={(e)=>{
                      if (e.key === 'ArrowRight') {
                        visitorBtnRef.current.focus();
                      } else if (e.key === 'ArrowLeft') {
                        visitorBtnRef.current.focus();
                      }else if (e.key === "Enter") {
                        setUserType("New")
                        entryTypeRef.current.focus();
                      }
                    }}
                  >
                    New
                  </Button>
                  <Button
                    sx={{
                      textTransform: "none",
                      borderRadius: "1rem",
                      marginLeft: "1.5rem",
                      fontWeight: "915",
                    }}
                    onClick={() => setUserType("Visitor")}
                    style={{
                      border: "3px solid #fd7e14",
                      backgroundColor: `${
                        userType === "Visitor" ? "#fd7e14" : "#fff"
                      }`,
                      color: `${userType === "Visitor" ? "#fff" : "black"}`,
                    }}
                    onKeyDown={(e)=>{
                      if (e.key === 'ArrowRight') {
                        newBtnRef.current.focus();
                      } else if (e.key === 'ArrowLeft') {
                        newBtnRef.current.focus();
                      }else if (e.key === "Enter") {
                        setUserType("Visitor")
                        entryTypeRef.current.focus();
                      }
                    }}
                    variant="contained"
                    ref={visitorBtnRef}
                  >
                    Visitor
                  </Button>
                </div>
              </>
            ) : null}
            <div className="form-floating mb-2" style={{ width: "100%" }}>
              <input
                type="text"
                className="form-control"
                disabled={true}
                onChange={(e) => setMemberFullName(e.target.value)}
                value={userType ? userType : memberFullName}
                ref={textFieldForFullnameRef}
                onKeyDown={(event) => {
                  const { key } = event;
                  if (key === "Enter") {
                    entryTypeRef.current.focus();
                  }
                }}
                placeholder="Full Name"
              />
              <label htmlFor="floatingInput">{t("Full Name")}</label>
            </div>
            {memberNameError ? (
              <Typography
                variant="paragraph"
                sx={{ fontSize: "14px", color: "red" }}
              >
                {memberNameError}
              </Typography>
            ) : null}
            {memberFoundStatus ? (
              <>
                <Typography
                  variant="paragraph"
                  sx={{ fontSize: "14px", color: "red" }}
                >
                  {memberFoundMessage}
                </Typography>
              </>
            ) : null}
            <FormControl sx={{ width: "100%" }}>
              <InputLabel shrink htmlFor="select-multiple-native">
                Entry Type
              </InputLabel>
              <Select
                key={elementRefreshCounter}
                inputRef={entryTypeRef}
                onKeyDown={(event) => {
                  const { key } = event;
                  if (key === "Enter") {
                    if (priceOfEvent > 1) {
                      paymentTypeRef.current.focus();
                    } else {
                      submitBtnRef.current.click();
                    }
                  }
                }}
                multiple
                native
                value={selectedEntryType}
                onChange={(e) => handleChangeEventTypeMode(e)}
                label="Entry Type"
                inputProps={{
                  id: "select-multiple-native",
                }}
              >
                {entryType?.map((entryTypeItem, index) => (
                  <option
                    className="fi-at-ent"
                    key={index}
                    value={entryTypeItem?.entryTypeID}
                  >
                    <span>{entryTypeItem.entryTypeName}</span>
                    <span>{`£${entryTypeItem.entryFee}`}</span>
                  </option>
                ))}
              </Select>
            </FormControl>
            {entryTypeError ? (
              <Typography
                variant="paragraph"
                sx={{ fontSize: "14px", color: "red" }}
              >
                {entryTypeError}
              </Typography>
            ) : null}
            <FormControl sx={{ width: "100%", mt: "10px" }}>
              <InputLabel shrink htmlFor="select-multiple-native">
                Payment Mode
              </InputLabel>
              <Select
                key={elementRefreshCounter}
                inputRef={paymentTypeRef}
                onKeyDown={(event) => {
                  const { key } = event;
                  if (key === "Enter") {
                    submitBtnRef.current.click();
                  }
                }}
                multiple
                native
                value={paymentMode}
                disabled={priceOfEvent < 1}
                onChange={(e) => handleChangePaymentMode(e)}
                label="Payment Mode"
                inputProps={{
                  id: "select-multiple-native",
                }}
              >
                <option className="fi-at-opt" value="Card">
                  Card
                </option>
                <option className="fi-at-opt" value="Cash">
                  Cash
                </option>
                <option className="fi-at-opt" value="Online">
                  Online
                </option>
              </Select>
            </FormControl>
            <div style={{ marginTop: "40px" }}>
              <Button
                ref={submitBtnRef}
                sx={{
                  textTransform: "none",
                  width: "100%",
                  backgroundColor: "#F87B03",
                  m: "auto",
                  mt: "10px",
                  bottom: "1rem",
                  color: "#fff",
                  p: "0.5rem 0.5rem",
                  "&:hover": {
                    backgroundColor: "#F87B03",
                  },
                }}
                onClick={handleCreateAttendance}
              >
                {t("Submit")}
              </Button>
            </div>
            {scanQRPressed && (
              <div
                className="modal modalOffline show"
                style={{
                  display: "block",
                  margin: "3rem 0 0 0rem",
                  backgroundColor: "rgba(255, 255, 255, 0.75)",
                }}
              >
                <Modal.Dialog
                  className="offlineData"
                  sx={{ margin: "9rem 0 0 0rem" }}
                >
                  <Modal.Body>
                    <h5>Scan the QR</h5>
                    <QrCodeScanner
                      qrCodeMemberId={qrCodeMemberId}
                      setQrCodeMemberId={setQrCodeMemberId}
                      t={t}
                      recordAttendence={recordAttendence}
                    />
                  </Modal.Body>

                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => setScanQRPressed(false)}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal.Dialog>
              </div>
            )}
          </Box>
        </>
      ) : null}
      {renderEmailAlert()}
    </div>
  );
};

export default FindMemberAttendanceOffline;
