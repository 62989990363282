import {
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TableSortLabel,
  TablePagination,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import { useNavigate, useLocation, Link } from "react-router-dom";
import RecordAttendance from "./RecordAttendance";
import RecordAttendance1 from "./RecordAttendance1";
import UpdateAttendance from "./UpdateAttendance";
import AddExpense from "./AddExpense";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import RecordAttendanceOffline from "./RecordAttendanceOffline";
import UpdateAttendanceOffline from "./UpdateAttendanceOffline";
import SyncRecordAttendanceData from "./SyncRecordAttendanceData";
import getSymbolFromCurrency from "currency-symbol-map";
import BackupIcon from "@mui/icons-material/Backup";
import configData from "./../../config.js";
import { phoneNoMapping, maskEmail } from "../CommonFunctions.js";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { useLoader } from "../../components/SpinnerContext";

const Styles = styled.div`
  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    height: 38px;
    width: 6.2rem;
    text-align: center;
  }
`;

const EventDetails = ({
  handleModal,
  mode,
  // addMemberOfflineDataCallback,
  addMemberOfflineData,
  setEventId,
  entryTypeData,
  attendanceData,
}) => {
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const pages = [5, 10, 15];
  const [expenditurePage, setExpenditurePage] = useState(0);
  const [expenditureRowsPerPage, setExpenditureRowsPerPage] = useState(
    pages[expenditurePage]
  );
  const { setIsLoading } = useLoader();
  const [addExpense, setAddExpense] = useState(false);
  const [recordAttendence, setRecordAttendence] = useState(false);
  const [updateAttendance, setUpdateAttendance] = useState(false);
  const [event, setEvent] = useState([]);
  const [booking, setBooking] = useState([]);
  const [attendances, setAttendances] = useState([]);
  const [attendance] = useState([]);
  const [initialAttendances, setInitialAttendances] = useState([]);
  const [filterEntryType, setFilterEntryType] = useState("");
  const [filterGender, setFilterGender] = useState("");
  const [filterMembershipCard, setFilterMembershipCard] = useState("");
  const [filterExpenseType, setFilterExpenseType] = useState("");
  const [genders, setGenders] = useState([]);
  const [entryTypes, setEntryTypes] = useState([]);
  const [expenditures, setExpenditures] = useState([]);
  const [expenditureTotalAmount, setExpenditureTotalAmount] = useState([]);
  const [initialExpenditures, setInitialExpenditures] = useState([]);
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [, setExpenseType] = useState();
  const [, setAmount] = useState();
  const [, setDescription] = useState();
  const [eventExpenseTypes, setEventExpenseTypes] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("token");
  const territoryNumber = localStorage.getItem("territoryNumber");
  const eventTypeName = location.state ? location.state.eventTypeName : "";
  const [dateRange] = useState([null, null]);
  const [startDate] = dateRange;
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const { t } = useTranslation();
  const [expenseUpdated, setExpenseUpdated] = useState(false);
  const [eventID, setEventID] = useState(
    location.state ? location.state.eventId : ""
  );
  const [
    addMemberOfflineSyncErrorMessage,
    setAddMemberOfflineSyncErrorMessage,
  ] = useState("");
  const [
    addMemberOfflineSyncSuccessMessage,
    setAddMemberOfflineSyncSuccessMessage,
  ] = useState("");
  const [
    createAttendanceOfflineSyncErrorMessage,
    setCreateAttendanceOfflineSyncErrorMessage,
  ] = useState("");
  const [
    createAttendanceOfflineSyncSuccessMessage,
    setCreateAttendanceOfflineSyncSuccessMessage,
  ] = useState("");
  const [
    updateAttendanceOfflineSyncErrorMessage,
    setUpdateAttendanceOfflineSyncErrorMessage,
  ] = useState("");
  const [
    updateAttendanceOfflineSyncSuccessMessage,
    setUpdateAttendanceOfflineSyncSuccessMessage,
  ] = useState("");
  const [attendanceRecorded, setAttendanceRecorded] = useState(false);
  const [capacityWarningMessage, setCapacityWarningMessage] = useState("");
  const indexedDbData = location.state ? location.state.offlineData : false;
  const [, setOfflineData] = useState(indexedDbData);
  const [, setAddMemberOffline] = useState(addMemberOfflineData);
  const [sync, setSync] = useState(false);
  const [offlineCreateAttendanceData, setOfflineCreateAttendanceData] =
    useState([]);
  const [offlineUpdateMemberData, setOfflineUpdateMemberData] = useState([]);
  const [offlineRecordAttendanceData, setOfflineRecordAttendanceData] =
    useState([]);
  const [offlineUpdateAttendanceData, setOfflineUpdateAttendanceData] =
    useState([]);
  const [offlineUpdateAddExpenditureData, setOfflineUpdateAddExpenditureData] =
    useState([]);
  const [deleteAttendanceData, setDeleteAttendanceData] = useState([]);
  const [offlineDeleteExpenseData, setOfflineDeleteExpenseData] = useState([]);

  //for CurrencyCode
  const currencyCode = localStorage.getItem("currencyCode");
  const currencySymbol = getSymbolFromCurrency(currencyCode);

  //To call Sync Component
  const [uploadDataFromIndexedDB, setUploadDataFromIndexedDB] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (location?.state?.eventId) {
      setEventID(location?.state?.eventId);
      setEventId(location?.state?.eventId);
    }
  }, [location]);

  const headCells = [
    {
      id: "memberCardNumber",
      label: t("Membership Card"),
      align: "center",
    },
    {
      id: "entryTypeName",
      label: t("Entry Type"),
      align: "center",
    },
    {
      id: "firstName",
      label: t("First Name"),
      align: "center",
    },
    {
      id: "lastName",
      label: t("Last Name"),
      align: "center",
    },
    {
      id: "gender",
      label: t("Gender"),
      align: "center",
    },
    {
      id: "email",
      label: t("Email"),
      disableSorting: true,
      align: "center",
    },
    {
      id: "mobileNumber",
      label: t("Contact Number"),
      disableSorting: true,
      align: "center",
    },
    {
      id: "entryTime",
      label: t("Check-In Time"),
      align: "center",
    },
  ];

  const expenditureHeadCells = [
    {
      id: "expenseTypeName",
      label: t("Type"),
      class: "text-sm-start",
      align: "center",
    },
    {
      id: "Description",
      label: t("Description"),
      disableSorting: true,
      class: "text-sm-start",
      align: "center",
    },
    {
      id: "AllocatedTo",
      label: t("Allocated to"),
      disableSorting: true,
      class: "text-sm-start",
      align: "center",
    },
    {
      id: "amount",
      label: t("Amount"),
      class: "text-sm-start",
      align: "center",
    },
    {
      id: "action",
      label: t("Action"),
      disableSorting: true,
      align: "center",
    },
  ];

  const getEventData = (type) => {
    switch (type) {
      case "male":
        return attendanceData?.filter((item) => item?.gender === 0).length ?? 0;
        break;
      case "female":
        return attendanceData?.filter((item) => item?.gender === 1).length ?? 0;
        break;
      case "total":
        return attendanceData?.length ?? 0;
        break;
      default:
        break;
    }
  };

  const fetchEventsData = async () => {
    const res = await fetch(`${configData.MEMBER_URL}events/${eventID}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await res.json();
    return data;
  };

  const fetchBookingAndAttendanceData = async () => {
    setIsLoading(true)
    const res = await fetch(
      `${configData.VENUE_URL}Attendance/${eventID}/getattendance`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setIsLoading(false)
    const data = await res.json();
    return data;
  };

  const fetchExpenditureData = async () => {
    const res = await fetch(
      `${configData.VENUE_URL}Events/${eventID}/getexpenditure`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await res.json();
    return data;
  };

  const handleExpenseModal = (value) => {
    setExpenseType("");
    if (value) {
      setAmount("");
      setDescription("");
      setAddExpense(value);
      if (mode === "online") {
        fetchExpenseTypesData().then((data) => {
          if (data.isError) {
            navigate("/");
          }

          if (data.statusCode === 200) {
            const eventExpenseTypes = data.payload;
            setEventExpenseTypes(eventExpenseTypes);
          }
        });
      } else {
        let request = window.indexedDB.open("MyDatabase", 1);
        request.onsuccess = (event) => {
          let db = event.target.result;
          //Fetching Expenses data from indexedDB
          let transaction2 = db.transaction(`Expensetypes`, "readonly");
          let objectStore2 = transaction2.objectStore(`Expensetypes`);
          let request2 = objectStore2.getAll();
          request2.onsuccess = (event) => {
            if (event.target.result.length) {
              setEventExpenseTypes(event.target.result[0]?.expensetypes);
            }
          };
        };
      }
    }
  };

  const fetchExpenseTypesData = async () => {
    const res = await fetch(`${configData.VENUE_URL}Events/expensetypes`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await res.json();
    return data;
  };

  const deleteEvent = async () => {
    handleClose();
    const res = await fetch(
      `${configData.VENUE_URL}Events/${eventID}/CompleteEvent`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res?.status === 200 || res?.status === 201) {
      navigate("/home");
    }
  };

  useEffect(() => {
    if (!window.navigator.onLine) {
      getExpense();
      let request = window.indexedDB.open("MyDatabase", 1);
      request.onsuccess = (event) => {
        let db = event.target.result;

        //Fetching EventDetails data
        let transaction = db.transaction(`EventInfo${eventID}`, "readonly");
        let objectStore = transaction.objectStore(`EventInfo${eventID}`);
        let request = objectStore.getAll();
        request.onsuccess = (event) => {
          setEvent(event.target.result[0]);
        };

        //Fetching Bookings Data
        let transaction1 = db.transaction(
          `EventBookings${eventID}`,
          "readonly"
        );
        let objectStore1 = transaction1.objectStore(`EventBookings${eventID}`);
        let request1 = objectStore1.getAll();
        request1.onsuccess = (event) => {
          setBooking(event.target.result[0]);
        };

        //Fetching Attendance Data
        let transaction2 = db.transaction(
          `EventAttendance${eventID}`,
          "readonly"
        );
        let objectStore2 = transaction2.objectStore(
          `EventAttendance${eventID}`
        );
        let request2 = objectStore2.getAll();
        request2.onsuccess = (event) => {
          console.log("event.target.result== ", event.target.result);
          setAttendances(event.target.result);
          setInitialAttendances(event.target.result);
          if (event.target.result.length > 0) {
            const genders = Array.from(
              new Set(event.target.result.map(({ gender }) => gender))
            );
            const entryTypes = Array.from(
              new Set(
                event.target.result.map(({ entryTypeName }) => entryTypeName)
              )
            );
            setGenders(genders);
            setEntryTypes(entryTypes);
          }
        };
      };
    }
  }, []);

  useEffect(() => {
    if (window.navigator.onLine) {
      let request = window.indexedDB.open("MyDatabase", 1);
      request.onsuccess = (event) => {
        let db = event.target.result;

        //Fetching CreateAttendance data from indexedDB
        let transaction = db.transaction(
          `CreateAttendance${eventID}`,
          "readonly"
        );
        let objectStore = transaction.objectStore(`CreateAttendance${eventID}`);
        let request = objectStore.getAll();
        request.onsuccess = (event) => {
          if (event.target.result.length) {
            console.log("CreateAttendance", event.target.result);
            setSync(true);
            setOfflineCreateAttendanceData(event.target.result);
          }
        };

        //Fetching CreateAttendance data from indexedDB
        let transaction5 = db.transaction(`UpdateMember`, "readonly");
        let objectStore5 = transaction5.objectStore(`UpdateMember`);
        let request5 = objectStore5.getAll();
        request5.onsuccess = (event) => {
          if (event.target.result.length) {
            console.log("UpdateMember", event.target.result);
            setSync(true);
            setOfflineUpdateMemberData(
              event.target.result[0]?.updateMemberRequests
            );
          }
        };

        //Fetching RecordAttendance data from indexedDB
        let transaction2 = db.transaction(
          `RecordAttendance${eventID}`,
          "readonly"
        );
        let objectStore2 = transaction2.objectStore(
          `RecordAttendance${eventID}`
        );
        let request2 = objectStore2.getAll();
        request2.onsuccess = (event) => {
          if (event.target.result.length) {
            console.log("RecordAttendance", event.target.result);
            setSync(true);
            setOfflineRecordAttendanceData(event.target.result);
          }
        };

        //Fetching UpdateAttendance data from indexedDB
        let transaction3 = db.transaction(
          `UpdateAttendance${eventID}`,
          "readonly"
        );
        let objectStore3 = transaction3.objectStore(
          `UpdateAttendance${eventID}`
        );
        let request3 = objectStore3.getAll();
        request3.onsuccess = (event) => {
          if (event.target.result.length) {
            console.log("UpdateAttendance", event.target.result);
            setSync(true);
            setOfflineUpdateAttendanceData(event.target.result);
          }
        };

        //Fetching UpdateAttendance data from indexedDB
        let transaction4 = db.transaction(
          `AddExpenditure${eventID}`,
          "readonly"
        );
        let objectStore4 = transaction4.objectStore(`AddExpenditure${eventID}`);
        let request4 = objectStore4.getAll();
        request4.onsuccess = (event) => {
          if (event.target.result.length) {
            setSync(true);
            setOfflineUpdateAddExpenditureData(event.target.result);
          }
        };

        //Fetching DeleteAttendance data from indexedDB
        let transaction6 = db.transaction(
          `DeleteAttendance${eventID}`,
          "readonly"
        );
        let objectStore6 = transaction6.objectStore(
          `DeleteAttendance${eventID}`
        );
        let request6 = objectStore6.getAll();
        request6.onsuccess = (event) => {
          if (event.target.result.length) {
            setSync(true);
            setDeleteAttendanceData(event.target.result);
          }
        };

        //Fetching DeleteAttendance data from indexedDB
        let transaction7 = db.transaction(
          `DeleteExpenditure${eventID}`,
          "readonly"
        );
        let objectStore7 = transaction7.objectStore(
          `DeleteExpenditure${eventID}`
        );
        let request7 = objectStore7.getAll();
        request7.onsuccess = (event) => {
          if (event.target.result.length) {
            setSync(true);
            setOfflineDeleteExpenseData(event.target.result);
          }
        };
      };
    }
  }, [window.navigator.onLine]);

  const getExpense = async () => {
    if (mode === "online") {
      fetchExpenditureData().then((data) => {
        if (data.statusCode === 200) {
          setExpenditures(data.payload.expenditures);
          console.log(data);
          setExpenditureTotalAmount(data.payload.totalExpenditure);
          setInitialExpenditures(data.payload.expenditures);
          if (data.payload.expenditures) {
            const expenseTypes = Array.from(
              new Set(
                data.payload.expenditures.map(
                  ({ expenseTypeName }) => expenseTypeName
                )
              )
            );
            setExpenseTypes(expenseTypes);
          }
        } else {
          setExpenditures([]);
          setExpenditureTotalAmount(0);
        }
      });
    } else {
      let exp = [];
      let request = window.indexedDB.open("MyDatabase", 1);
      request.onsuccess = (event) => {
        let db = event.target.result;
        //Fetching Expenses data from indexedDB
        let transaction2 = db.transaction(`Expenditure${eventID}`, "readonly");
        let objectStore2 = transaction2.objectStore(`Expenditure${eventID}`);
        let request2 = objectStore2.getAll();
        request2.onsuccess = (event) => {
          if (event.target.result.length) {
            setExpenditures(event.target.result[0]?.data?.expenditures);
            setInitialExpenditures(event.target.result[0]?.data?.expenditures);
            exp = event.target.result[0]?.data?.expenditures;
          }
        };
        let transaction3 = db.transaction(
          `AddExpenditure${eventID}`,
          "readonly"
        );
        let objectStore3 = transaction3.objectStore(`AddExpenditure${eventID}`);
        let request3 = objectStore3.getAll();
        request3.onsuccess = (event) => {
          if (event.target.result.length) {
            let data = exp
              ? [...exp, ...event.target.result]
              : [...event.target.result];
            setExpenditures(data);
            setInitialExpenditures(data);
          }
        };
      };
    }
  };

  useEffect(() => {
    if (mode === "offline") {
      const expenseTypes = Array.from(
        new Set(expenditures?.map(({ expenseTypeName }) => expenseTypeName))
      );
      setExpenseTypes(expenseTypes);
    }
  }, [expenditures]);

  useEffect(() => {
    let totalExpense = 0;
    initialExpenditures?.forEach((e) => {
      totalExpense = totalExpense + e.amount ?? 0;
    });
    setExpenditureTotalAmount(totalExpense);
  }, [initialExpenditures]);

  useEffect(() => {
    if (window.navigator.onLine) {
      fetchEventsData().then((data) => {
        if (data.isError) {
          navigate("/");
        }

        if (data.message === "GET Request successful.") {
          const eventData = data.result;
          setEvent(eventData);
        }
      });

      fetchExpenditureData().then((data) => {
        if (data.statusCode === 200) {
          setExpenditures(data.payload.expenditures);
          setExpenditureTotalAmount(data.payload.totalExpenditure);
          setInitialExpenditures(data.payload.expenditures);
          if (data.payload.expenditures) {
            const expenseTypes = Array.from(
              new Set(
                data.payload.expenditures.map(
                  ({ expenseTypeName }) => expenseTypeName
                )
              )
            );
            setExpenseTypes(expenseTypes);
          }
        } else {
          setExpenditures([]);
          setExpenditureTotalAmount(0);
        }
      });
      fetchBookingAndAttendanceData().then((data) => {
        if (data.isError) {
          navigate("/");
        }

        if (data.statusCode === 200) {
          const bookingAndAttendanceData = data.payload;
          if (bookingAndAttendanceData) {
            setBooking(bookingAndAttendanceData.attendanceHistoryCount);
            setAttendances(bookingAndAttendanceData.attendanceHistory);
            setInitialAttendances(bookingAndAttendanceData.attendanceHistory);
            if (attendances) {
              const genders = Array.from(
                new Set(
                  bookingAndAttendanceData.attendanceHistory.map(
                    ({ gender }) => gender
                  )
                )
              );
              const entryTypes = Array.from(
                new Set(
                  bookingAndAttendanceData.attendanceHistory.map(
                    ({ entryTypeName }) => entryTypeName
                  )
                )
              );
              setGenders(genders);
              setEntryTypes(entryTypes);
            }
          }
        }
      });
    }
  }, [
    addMemberOfflineSyncSuccessMessage,
    createAttendanceOfflineSyncSuccessMessage,
    updateAttendanceOfflineSyncSuccessMessage,
    attendanceRecorded,
  ]);

  useEffect(() => {
    if (expenseUpdated) {
      getExpense();
      setExpenseUpdated(false);
    }
  }, [expenseUpdated]);

  const formattedDate = (date) => {
    let newDate = new Date(date);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let mnth = newDate.getMonth();
    let day = ("0" + newDate.getDate()).slice(-2);
    return [day, monthNames[mnth], newDate.getFullYear()].join(" ");
  };

  const formattedTime = (date) => {
    let newDate = new Date(date);
    return [
      ("0" + newDate.getHours()).slice(-2),
      ("0" + newDate.getMinutes()).slice(-2),
    ].join(":");
  };

  const handleExpenseType = (value) => {
    setFilterExpenseType(value);
    const filterData = initialExpenditures.filter((expense) => {
      return expense.expenseTypeName
        .toLowerCase()
        .includes(value.toLowerCase());
    });
    setExpenditures(filterData);
  };

  const handleClearAttendance = () => {
    setAttendances(initialAttendances);
    setFilterEntryType("");
    setFilterGender("");
    setFilterMembershipCard("");
    setStartTime(null);
    setEndTime(null);
  };

  const handleClearExpenditures = () => {
    setExpenditures(initialExpenditures);
    setFilterExpenseType("");
  };

  const handleExpenditureChangePage = (event, newPage) => {
    setExpenditurePage(newPage);
  };

  const handleExpendituresChangeRowsPerPage = (event) => {
    setExpenditureRowsPerPage(parseInt(event.target.value, 10));
    setExpenditurePage(0);
  };

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const isTimeRowWithinTimeRange = (date) => {
    const eventTime = new Date();
    const eventTimeHours = date ? date.split(" ")[1] : "";
    eventTime.setHours(eventTimeHours.split(":")[0]);
    eventTime.setMinutes(eventTimeHours.split(":")[1]);
    let now_utc_startTime;
    let newStartTime;
    let newEndTime;
    if (startTime) {
      now_utc_startTime = new Date(
        startTime.getTime() - startTime.getTimezoneOffset() * 60000
      ).toISOString();
      newStartTime = new Date();
      const startTimeHours = now_utc_startTime.split("T")[1];
      newStartTime.setHours(startTimeHours.split(":")[0]);
      newStartTime.setMinutes(startTimeHours.split(":")[1]);
    }
    if (endTime) {
      let now_utc_endTime = new Date(
        endTime.getTime() - endTime.getTimezoneOffset() * 60000
      ).toISOString();
      newEndTime = new Date();
      const endTimeHours = now_utc_endTime.split("T")[1];
      newEndTime.setHours(endTimeHours.split(":")[0]);
      newEndTime.setMinutes(endTimeHours.split(":")[1]);
    }
    if (eventTime >= newStartTime && eventTime <= newEndTime) {
      return true;
    } else return false;
  };

  const isTimeRangeValid = (startTime, endTime) => {
    return startTime === null || endTime === null;
  };

  const isDateRangeValid = (date) => {
    return date[0] === null || date[1] === null;
  };

  const isDataRowWithinDateRange = (date) => {
    const eventDate = Date.parse(date?.split("T")[0]);

    let endDate;
    if (dateRange[1]) {
      let now_utc_endDate = new Date(
        dateRange[1].getTime() - dateRange[1].getTimezoneOffset() * 60000
      ).toISOString();
      endDate = Date.parse(now_utc_endDate);
    }
    if (eventDate >= startDate && eventDate <= endDate) {
      return true;
    } else return false;
  };

  const handleChange = (e) => {
    let value = e.target.value;
    if (e?.target?.name === "membershipCardNumber") {
      console.log(e?.target?.name);
      setFilterMembershipCard(value);
    }
    if (e?.target?.name === "entryType") {
      setFilterEntryType(value);
    }
    if (e?.target?.name === "gender") {
      setFilterGender(value);
    }
  };

  useEffect(() => {
    let dataSet = initialAttendances.filter((item) => {
      isTimeRowWithinTimeRange(item?.entryTime);
      if (
        (!filterMembershipCard ||
          item.memberCardNumber
            .toString()
            .includes(filterMembershipCard.toString())) &&
        (!filterEntryType || filterEntryType === item?.entryTypeName) &&
        (!filterGender || filterGender === item?.gender) &&
        (isDateRangeValid(dateRange) ||
          isDataRowWithinDateRange(item?.entryTime)) &&
        (isTimeRangeValid(startTime, endTime) ||
          isTimeRowWithinTimeRange(item?.entryTime))
      ) {
        return true;
      }
      return false;
    });
    setAttendances(dataSet);
  }, [
    filterMembershipCard,
    filterEntryType,
    filterGender,
    dateRange,
    startTime,
    endTime,
  ]);

  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function memberCallback(syncResponse) {
    if (syncResponse.statusCode !== 200 || syncResponse.result === false) {
      setAddMemberOfflineSyncErrorMessage("");
      setTimeout(() => {
        setAddMemberOfflineSyncErrorMessage("Some error has occured");
      }, 3000);
    } else {
      setAddMemberOfflineSyncSuccessMessage("Offline data synced successfully");
      setTimeout(() => {
        setAddMemberOfflineSyncSuccessMessage("");
      }, 3000);
    }
  }

  function createAttendanceCallback(syncResponse) {
    if (syncResponse.statusCode !== 200) {
      setCreateAttendanceOfflineSyncErrorMessage("");
      setTimeout(() => {
        setCreateAttendanceOfflineSyncErrorMessage("");
      }, 3000);
    } else {
      setCreateAttendanceOfflineSyncSuccessMessage(
        "Offline data synced successfully"
      );
      setTimeout(() => {
        setCreateAttendanceOfflineSyncSuccessMessage("");
      }, 3000);
    }
  }

  function updateAttendanceCallback(syncResponse) {
    if (syncResponse.statusCode !== 200) {
      setUpdateAttendanceOfflineSyncErrorMessage(syncResponse.errorMessage);
      setTimeout(() => {
        setUpdateAttendanceOfflineSyncErrorMessage("");
      }, 3000);
    } else {
      setUpdateAttendanceOfflineSyncSuccessMessage(
        "Offline data synced successfully"
      );
      setTimeout(() => {
        setUpdateAttendanceOfflineSyncSuccessMessage("");
      }, 3000);
    }
  }

  function callbackFunction(value) {
    if (value) {
      setAttendanceRecorded(value);
    }
  }

  useEffect(() => {
    if (booking?.availableSeats) {
      let capacityAvailable =
        (booking?.availableSeats / booking.totalSeats) * 100;
      capacityAvailable = Math.round(capacityAvailable);
      if (capacityAvailable === 10) {
        setCapacityWarningMessage(
          "!Attention: Only 10% capacity left for the event"
        );
      }
      setTimeout(() => {
        setCapacityWarningMessage("");
      }, 4000);
    }
  }, [booking]);

  function offlineDataCallback(value) {
    if (!value) {
      setOfflineData(value);
    } else {
      setOfflineData(value);
    }
  }

  function addMemberSyncDataCallback(value) {
    if (!value) {
      setAddMemberOffline(value);
    }
  }

  useEffect(() => {
    if (addMemberOfflineData) {
      setAddMemberOffline(addMemberOfflineData);
    }
  }, [addMemberOfflineData]);

  useEffect(() => {
    if (mode === "offline") {
      let request = window.indexedDB.open("MyDatabase", 1);
      request.onsuccess = (event) => {
        if (event.target.result.objectStoreNames.length === 0) {
          request.result.close();
          let req = indexedDB.deleteDatabase("MyDatabase", 1);
          req.onsuccess = function () {
            console.log("Deleted database successfully");
          };
          req.onerror = function () {
            console.log("Couldn't delete database");
          };
          req.onblocked = function () {
            console.log(
              "Couldn't delete database due to the operation being blocked"
            );
          };
        }
      };
    }
  }, []);

  const deleteExpense = (expense) => {
    if (mode !== "offline") {
      axios
        .delete(
          `${configData.VENUE_URL}Events/deleteexpense/${expense.expenseId}/${expense.eventId}`,
          {
            method: "DELETE",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          getExpense();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log(expense);
      let flag = 0;
      let request = window.indexedDB.open("MyDatabase", 1);
      request.onsuccess = (event) => {
        let db = event.target.result;
        //Fetching Expenses data from indexedDB
        let transaction2 = db.transaction(`Expenditure${eventID}`, "readonly");
        let objectStore2 = transaction2.objectStore(`Expenditure${eventID}`);
        let request2 = objectStore2.getAll();
        request2.onsuccess = (event) => {
          if (event.target.result.length) {
            let data = event.target.result[0]?.data?.expenditures;
            let check = data.filter((e) => e.expenseId === expense.expenseId);
            if (check && check.length > 0) {
              flag = 1;
              let finalData = data.filter(
                (e) => e.expenseId !== expense.expenseId
              );
              let request = window.indexedDB.open("MyDatabase", 1);
              request.onsuccess = (event) => {
                let db = event.target.result;
                let transaction = db.transaction(
                  `Expenditure${eventID}`,
                  "readwrite"
                );
                let objectStore = transaction.objectStore(
                  `Expenditure${eventID}`
                );
                objectStore.clear();
                objectStore.put({ data: { expenditures: finalData }, id: 8 });
                getExpense();
              };
            }
          }
          if (flag === 1) {
            let transaction3 = db.transaction(
              `DeleteExpenditure${eventID}`,
              "readwrite"
            );
            let objectStore3 = transaction3.objectStore(
              `DeleteExpenditure${eventID}`
            );
            objectStore3.put({ expenseId: expense?.expenseId });
          }
        };
        if (flag === 0) {
          let transaction3 = db.transaction(
            `AddExpenditure${eventID}`,
            "readonly"
          );
          let objectStore3 = transaction3.objectStore(
            `AddExpenditure${eventID}`
          );
          let request3 = objectStore3.getAll();
          request3.onsuccess = (event) => {
            if (event.target.result.length) {
              let data = event.target.result;
              let check = data.filter(
                (e) => e.expenseTypeId === expense.expenseTypeId
              );
              if (check && check.length > 0) {
                flag = 1;
                let finalData = data.filter(
                  (e) => e.expenseTypeId !== expense.expenseTypeId
                );
                let request = window.indexedDB.open("MyDatabase", 1);
                request.onsuccess = (event) => {
                  let db = event.target.result;
                  let transaction = db.transaction(
                    `AddExpenditure${eventID}`,
                    "readwrite"
                  );
                  let objectStore = transaction.objectStore(
                    `AddExpenditure${eventID}`
                  );
                  objectStore.clear();
                  finalData.map((e) => {
                    objectStore.put(e);
                  });
                  getExpense();
                };
              }
            }
          };
        }
      };
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const renderReconciliationModal = () => {
    if (show) {
      return (
        <div
          className="modal modalOffline show"
          style={{
            display: "block",
            margin: "3rem 0 0 0rem",
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          }}
        >
          <Modal.Dialog
            className="offlineData"
            sx={{ margin: "9rem 0 0 0rem" }}
          >
            <Modal.Header>
              <Modal.Title>Event reconciliation confirmation</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div>
                <span>Are you sure – this cannot be undone</span>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={() => handleClose()}>
                Close
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  deleteEvent();
                }}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Modal.Dialog>
        </div>
      );
    }
  };

  const getExpenseTypeName = (exp) => {
    const dd = eventExpenseTypes.filter(
      (e) => e.expenseTypeID === exp.expenseTypeId
    );
    return dd[0]?.expenseTypeName ?? "";
  };

  const getMemberName = (userId) => {
    if (userId) {
      const user = attendances.filter((item) => item.memberID === userId);
      return user[0]?.firstName
        ? `${user[0].firstName} ${user[0].lastName}`
        : "";
    } else {
      return "";
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Box sx={{ ml: "1rem", mt: "1rem" }}>
        <h4>{t("EVENT DETAILS")}</h4>
      </Box>
      {capacityWarningMessage ? (
        <h3 className="text-danger mt-3" style={{ textAlign: "end" }}>
          {capacityWarningMessage}
        </h3>
      ) : null}
      <Box
        sx={{
          border: "1px solid white",
          borderRadius: "1.5rem",
          backgroundColor: "#E7E9EB",
          marginTop: "1rem",
          padding: "10px 10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "2rem",
          }}
        >
          <Box sx={{ marginLeft: "2rem", marginBottom: "1rem", width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                m: "0px 1rem",
              }}
            >
              <Typography
                className="text-capitalize"
                sx={{ fontSize: "21px", fontWeight: "bold" }}
                variant="h3"
              >
                {event.eventName}
              </Typography>
              <div>
                <Button
                  sx={{
                    textTransform: "none",
                    borderRadius: "2rem",
                    color: "#fff",
                    marginLeft: "11rem",
                    fontWeight: "915",
                    backgroundColor: "#54aa1b",
                    "&:hover": {
                      backgroundColor: "#54aa1b",
                    },
                  }}
                  variant="contained"
                >
                  {event.eventStatusName}
                </Button>
                {mode === "online" && (
                  <Button
                    sx={{
                      textTransform: "none",
                      borderRadius: "2rem",
                      color: "#fff",
                      marginLeft: "1rem",
                      fontWeight: "915",
                      backgroundColor: "#FF2400",
                      "&:hover": {
                        backgroundColor: "#FF2400",
                      },
                    }}
                    variant="contained"
                    onClick={() => {
                      setShow(true);
                    }}
                  >
                    Event reconciliation complete and submit
                  </Button>
                )}
              </div>
            </Box>
            <Typography
              paragraph
              sx={{
                display: "flex",
                justifyContent: "space-between",
                m: "0px 1rem",
                minHeight: "3rem",
                minWidth: "50rem",
              }}
            >
              {event?.eventDescriptionTXT?.length > 1
                ? event.eventDescriptionTXT
                : "N/A"}
            </Typography>
            <Button
              sx={{
                marginLeft: "1rem",
                background: "white",
                textTransform: "capitalize",
              }}
              variant="outlined"
              className="rounded-3 text-bg-light border-0"
              startIcon={<LocationOnOutlinedIcon sx={{ color: "#fd7e14" }} />}
            >
              {event.venueKnownAs}
            </Button>
            <Button
              sx={{
                marginLeft: "1rem",
                background: "white",
                textTransform: "capitalize",
              }}
              variant="outlined"
              className="rounded-3 text-bg-light border-0"
              startIcon={
                <CalendarMonthOutlinedIcon sx={{ color: "#fd7e14" }} />
              }
            >
              {formattedDate(event.eventStart)}
            </Button>
            <Button
              sx={{
                marginLeft: "1rem",
                background: "white",
                textTransform: "capitalize",
              }}
              variant="outlined"
              className="rounded-3 text-bg-light border-0"
              startIcon={<AccessTimeOutlinedIcon sx={{ color: "#fd7e14" }} />}
            >
              {formattedTime(event.eventStart)}
            </Button>
          </Box>
        </Box>
        <Box>
          <div className="mt-6">
            <table className="table  bg-light mt-3 border-white rounded rounded-4 overflow-hidden">
              <thead style={{ height: "3px", lineHeight: "3px" }}>
                <tr
                  className="background-white text-truncate text-center"
                  style={{ color: "black !important" }}
                >
                  <th>{t("Total Seats")}</th>
                  <th>{t("Seats Available")}</th>
                  <th>{t("Pre-Booked")}</th>
                  <th>{t("Entry Type")}</th>
                  <th>{t("Attendance")}</th>
                </tr>
              </thead>
              <tbody style={{ height: "3px", lineHeight: "3px" }}>
                {mode === "online" ? (
                  <tr>
                    <td align="center">{booking?.totalSeats}</td>
                    <td align="center">{booking?.availableSeats}</td>
                    <td align="center">{booking?.totalBookings}</td>
                    <td align="center">{booking?.totalEntryTypes}</td>
                    <td align="center">{booking?.eventsAttended}</td>
                  </tr>
                ) : (
                  <tr>
                    <td align="center">{booking?.totalSeats}</td>
                    <td align="center">
                      {booking?.totalSeats - getEventData("total")}
                    </td>
                    <td align="center">{getEventData("total")}</td>
                    <td align="center">{booking?.totalEntryTypes}</td>
                    <td align="center">{getEventData("total")}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Box>
        <Box>
          <div className="mt-6">
            <div className="cont">
              {entryTypeData &&
                entryTypeData.map((block) => (
                  <div key={block} className="block">
                    <div style={{ fontWeight: "bold" }}>
                      {block?.entryTypeName}
                    </div>
                    <div style={{ fontSize: "16px" }}>
                      {block?.attendanceCount ?? 0}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </Box>
        <Box></Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          m: "1rem",
        }}
      >
        <Box sx={{ display: "flex", gap: "0.5rem" }}>
          <h6
            className={
              addMemberOfflineSyncErrorMessage ? "text-danger" : "text-success"
            }
          >
            {addMemberOfflineSyncErrorMessage
              ? addMemberOfflineSyncErrorMessage
              : addMemberOfflineSyncSuccessMessage}
          </h6>
          <h6
            className={
              createAttendanceOfflineSyncErrorMessage
                ? "text-danger"
                : "text-success"
            }
          >
            {createAttendanceOfflineSyncErrorMessage
              ? createAttendanceOfflineSyncErrorMessage
              : createAttendanceOfflineSyncSuccessMessage}
          </h6>
          <h6
            className={
              updateAttendanceOfflineSyncErrorMessage
                ? "text-danger"
                : "text-success"
            }
          >
            {updateAttendanceOfflineSyncErrorMessage
              ? updateAttendanceOfflineSyncErrorMessage
              : updateAttendanceOfflineSyncSuccessMessage}
          </h6>
          {mode === "offline" ? (
            <></>
          ) : sync ? (
            <Button
              sx={{ float: "right", m: "0.1rem" }}
              onClick={() => setUploadDataFromIndexedDB(true)}
            >
              <Tooltip title="Sync Offline Data">
                <BackupIcon
                  style={{ color: "green", height: "2rem", fontSize: "5rem" }}
                />
              </Tooltip>
            </Button>
          ) : null}
          {uploadDataFromIndexedDB ? (
            <SyncRecordAttendanceData
              eventID={eventID}
              handleCallback={memberCallback}
              setUploadDataFromIndexedDB={setUploadDataFromIndexedDB}
              createAttendanceCallback={createAttendanceCallback}
              updateAttendanceCallback={updateAttendanceCallback}
              offlineDataCallback={offlineDataCallback}
              addMemberSyncDataCallback={addMemberSyncDataCallback}
              offlineCreateAttendanceData={offlineCreateAttendanceData}
              offlineRecordAttendanceData={offlineRecordAttendanceData}
              offlineUpdateAttendanceData={offlineUpdateAttendanceData}
              offlineUpdateAddExpenditureData={offlineUpdateAddExpenditureData}
              offlineUpdateMemberData={offlineUpdateMemberData}
              deleteAttendanceData={deleteAttendanceData}
              offlineDeleteExpenseData={offlineDeleteExpenseData}
              setSync={setSync}
              // addMemberOfflineData={addMemberOfflineData}
            />
          ) : null}
        </Box>
      </Box>
      {recordAttendence ? (
        mode === "online" ? (
          <RecordAttendance
            recordAttendence={recordAttendence}
            setRecordAttendence={setRecordAttendence}
            setAttendances={setAttendances}
            setBooking={setBooking}
            eventID={eventID}
            handleModal={handleModal}
            eventTypeName={eventTypeName}
            mode={mode}
          />
        ) : (
          <RecordAttendanceOffline
            recordAttendence={recordAttendence}
            setRecordAttendence={setRecordAttendence}
            setAttendances={setAttendances}
            setBooking={setBooking}
            eventID={eventID}
            handleModal={handleModal}
            eventTypeName={eventTypeName}
            t={t}
            mode={mode}
          />
        )
      ) : null}
      <Accordion
        defaultExpanded={true}
        style={{ borderRadius: "10px", marginTop: "10px" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            sx={{
              fontSize: "22px",
              textTransform: "uppercase",
              fontWeight: "700",
              mr: "2rem",
            }}
          >
            {t("Attendance")}
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#00B4FF",
              fontWeight: 700,
              color: "#fff",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#00B4FF",
              },
            }}
            onClick={() => {
              navigate("/searchMember", { state: { eventID: eventID } });
            }}
          >
            {t("Record Attendance")}
          </Button>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <FormControl
              id="eventMembership"
              sx={{ m: 1, minWidth: 90, borderRadius: "90px" }}
            >
              <TextField
                size="small"
                placeholder={t("Membership Card")}
                type="text"
                name="membershipCardNumber"
                value={filterMembershipCard}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="demo-simple-select-label">
                {t("Entry Type")}
              </InputLabel>
              <Select
                sx={{ borderRadius: "9px" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filterEntryType}
                name="entryType"
                label={t("Entry Type")}
                onChange={handleChange}
              >
                {entryTypes.map((entryType, index) => (
                  <MenuItem key={index} value={entryType}>
                    {entryType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="demo-simple-select-label">
                {t("Gender")}
              </InputLabel>
              <Select
                sx={{ borderRadius: "9px" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filterGender}
                label={t("Gender")}
                name="gender"
                onChange={handleChange}
              >
                {genders.map((gender, index) => (
                  <MenuItem key={index} value={gender}>
                    {gender === 1 ? "Male" : gender === 2 ? "Female" : ""}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Styles>
              <div
                style={{
                  display: "flex",
                  gap: 0,
                  border: "1px solid #cbc0c0",
                  borderRadius: "9px",
                  alignItems: "center",
                  fontSize: "16px",
                  color: "grey",
                  marginTop: "0.5rem",
                }}
              >
                <strong>&nbsp;{t("Check-In Time")} &nbsp;</strong>
                <DatePicker
                  className="TimePicker"
                  placeholderText={t("Start Time")}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  selected={startTime}
                  onChange={(date) => setStartTime(date)}
                />
                <strong>-</strong>
                <DatePicker
                  className="TimePicker"
                  placeholderText={t("End Time")}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  selected={endTime}
                  minTime={startTime ? startTime : new Date().setHours(23)}
                  maxTime={new Date().setHours(23)}
                  onChange={(date) => {
                    setEndTime(date);
                  }}
                />
              </div>
            </Styles>

            <Button
              onClick={handleClearAttendance}
              sx={{ textTransform: "none", color: "red", fontWeight: "bold" }}
            >
              {t("Clear")}
            </Button>
          </Box>
          <div className="mt-3 event-table" style={{ overflowX: "auto" }}>
            <table className="table table-striped bg-light mt-3 p-3 border-white rounded-4 overflow-hidden">
              <thead>
                <tr className="text-white background-orange text-truncate">
                  {headCells.map((headCell, index) => (
                    <th
                      key={index}
                      align={headCell.align}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      {headCell.disableSorting ? (
                        headCell.label
                      ) : (
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : "asc"}
                          onClick={() => handleSortRequest(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {stableSort(attendances, getComparator(order, orderBy))?.map(
                  (attendance, index) => {
                    return (
                      <tr key={index}>
                        <td
                          sx={{ borderBottomLeftRadius: 0 }}
                          className="border-radius-0"
                          align="left"
                        >
                          {attendance.memberCardNumber}
                        </td>
                        <td align="left">{attendance.entryTypeName}</td>
                        <td align="left">{attendance.firstName}</td>
                        <td align="left">{attendance.lastName}</td>
                        <td align="left">
                          {attendance.gender === 1
                            ? "Male"
                            : attendance.gender === 2
                            ? "Female"
                            : ""}
                        </td>
                        <td align="left">
                          {attendance.email ? maskEmail(attendance.email) : ""}
                        </td>
                        <td align="left">
                          {attendance.mobileNumber
                            ? phoneNoMapping(attendance.mobileNumber)
                            : ""}
                        </td>
                        <td align="center">
                          {attendance.entryTime
                            ? formattedTime(attendance.entryTime)
                            : ""}
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        </AccordionDetails>
      </Accordion>
      <hr></hr>

      {updateAttendance ? (
        mode === "online" ? (
          <UpdateAttendance
            updateAttendance={updateAttendance}
            setUpdateAttendance={setUpdateAttendance}
            setAttendances={setAttendances}
            setBooking={setBooking}
            territoryNumber={territoryNumber}
            attendance={attendance}
            eventID={eventID}
          />
        ) : (
          <UpdateAttendanceOffline
            updateAttendance={updateAttendance}
            setUpdateAttendance={setUpdateAttendance}
            setAttendances={setAttendances}
            setBooking={setBooking}
            territoryNumber={territoryNumber}
            attendance={attendance}
            eventID={eventID}
            eventTypeName={eventTypeName}
            offlineDataCallback={offlineDataCallback}
          />
        )
      ) : null}

      {mode === "online" ? (
        <RecordAttendance1
          recordAttendence={recordAttendence}
          setRecordAttendence={setRecordAttendence}
          setAttendances={setAttendances}
          setBooking={setBooking}
          eventID={eventID}
          handleModal={handleModal}
          eventTypeName={eventTypeName}
          mode={mode}
          callbackFunction={callbackFunction}
        />
      ) : (
        <RecordAttendanceOffline
          recordAttendence={recordAttendence}
          setRecordAttendence={setRecordAttendence}
          setAttendances={setAttendances}
          setBooking={setBooking}
          eventID={eventID}
          handleModal={handleModal}
          eventTypeName={eventTypeName}
          t={t}
          mode={mode}
        />
      )}
      <>
        <Box
          sx={{
            display: "flex",
            m: "1rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "22px",
              textTransform: "uppercase",
              fontWeight: "700",
              mr: "2rem",
            }}
          >
            {t("Expenditure")}
          </Typography>
          <Box>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#00B4FF",
                fontWeight: 700,
                color: "#fff",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#00B4FF",
                },
              }}
              onClick={() => handleExpenseModal(true)}
            >
              {t("Add Expense")}
            </Button>
          </Box>
        </Box>
        <AddExpense
          eventID={eventID}
          modal={addExpense}
          mode={mode}
          expenditures={expenditures}
          setModal={setAddExpense}
          setExpenseUpdated={setExpenseUpdated}
          attendances={attendances}
          eventExpTypes={eventExpenseTypes}
          // parentCallback={handleAddExpenseModal}
          getExpense={getExpense}
        ></AddExpense>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
          }}
        >
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-simple-select-label">{t("Type")}</InputLabel>
            <Select
              sx={{ borderRadius: "9px" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filterExpenseType}
              label="Type"
              onChange={(e) => handleExpenseType(e.target.value)}
            >
              {expenseTypes.map((expenseType, index) => (
                <MenuItem key={index} value={expenseType}>
                  {expenseType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            onClick={handleClearExpenditures}
            sx={{ textTransform: "none", color: "red", fontWeight: "bold" }}
          >
            {t("Clear")}
          </Button>
        </Box>

        <div className="mt-3" style={{ overflowX: "auto" }}>
          <table className="table table-striped bg-light mt-3 p-3 border-white rounded-4 overflow-hidden">
            <thead>
              <tr className="text-white background-orange text-truncate text-center">
                {expenditureHeadCells.map((headCell) => (
                  <th
                    className={headCell.class}
                    align={headCell.align}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    {headCell.disableSorting ? (
                      headCell.label
                    ) : (
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={() => handleSortRequest(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {stableSort(expenditures, getComparator(order, orderBy))
                ?.slice(
                  expenditurePage * expenditureRowsPerPage,
                  (expenditurePage + 1) * expenditureRowsPerPage
                )
                ?.map((expenditure) => {
                  return (
                    <tr>
                      <td align="left">
                        {expenditure.expenseTypeName
                          ? expenditure.expenseTypeName
                          : getExpenseTypeName(expenditure)}
                      </td>
                      <td align="left">{expenditure.description}</td>
                      <td align="left">
                        {getMemberName(expenditure?.crewMemberId)}
                      </td>
                      <td align="left">
                        <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                          {currencySymbol}
                        </span>
                        {expenditure.amount
                          ? `${
                              expenditure.amount ===
                              Math.floor(expenditure.amount)
                                ? `${expenditure.amount.toLocaleString()}.00`
                                : `${expenditure.amount.toLocaleString()}`
                            }`
                          : "0.00"}
                      </td>
                      <td align="center">
                        <Link
                          onClick={() => deleteExpense(expenditure)}
                          style={{
                            textDecoration: "none",
                            marginLeft: "10px",
                            marginRight: "10px",
                            color: "red",
                          }}
                        >
                          Delete
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              <tr style={{ backgroundColor: "#dbf5db" }}>
                <td align="left" style={{ fontWeight: "bold" }}>
                  {t("Total")}
                </td>
                <td colSpan={2}></td>
                <td align="left" style={{ color: "green" }}>
                  <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                    {currencySymbol}
                  </span>
                  {expenditureTotalAmount
                    ? `${expenditureTotalAmount.toLocaleString()}`
                    : "0.00"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <TablePagination
          rowsPerPageOptions={pages}
          component="div"
          count={expenditures?.length}
          rowsPerPage={expenditureRowsPerPage}
          page={expenditurePage}
          onPageChange={handleExpenditureChangePage}
          onRowsPerPageChange={handleExpendituresChangeRowsPerPage}
        />
      </>
      {renderReconciliationModal()}
    </div>
  );
};

export default EventDetails;
